import { Cookie } from "../../site/scripts/utils";

enum Classnames {
  invalidInput = "invalid-input",
  confirmedInput = "confirmed-input",
  displayBlock = "display-block",
}

class FormText {
  // Elements
  element: HTMLElement;
  input: HTMLInputElement | HTMLTextAreaElement;
  inputWrapper: HTMLElement;
  errorMessageSpan: HTMLSpanElement;
  parentForm: HTMLFormElement;
  requiredMessage: string;
  constraintMessage: string;
  confirmationMessage: string;
  inputType: string;
  required: boolean;
  confirmationField: HTMLInputElement | HTMLTextAreaElement;
  cookieId: string;

  requiredMessageSelector = "data-cmp-required-message";
  constraintMessageSelector = "data-cmp-constraint-message";
  confirmationMessageSelector = "data-cmp-confirmation-message";

  constructor(el: HTMLElement) {
    this.element = el;
    this.input = this.element.querySelector("input");
    if (this.input == null) {
      this.input = this.element.querySelector("textarea");
    }

    this.inputWrapper = this.element.querySelector(
      `[data-cmp-hook-form-text="input-container"]`
    );
    this.parentForm = this.input.form;
    this.inputType = this.input.type;
    const confirmationId = this.element.dataset.fieldConfirmationId;
    this.confirmationField = document.querySelector(`#${confirmationId}`);

    const cookieId = this.element.dataset.fieldCookieId;

    if (cookieId) {
      const cookieValue = Cookie.get(cookieId) || "";
      if (this.input.value.trim().length === 0) {
        this.input.value = cookieValue;
      }
    }

    this.setupErrorMessages();
    this.addEventListeners();
  }

  setupErrorMessages(): void {
    this.requiredMessage = this.element.getAttribute(
      this.requiredMessageSelector
    );
    this.constraintMessage = this.element.getAttribute(
      this.constraintMessageSelector
    );
    this.confirmationMessage = this.element.getAttribute(
      this.confirmationMessageSelector
    );
    this.errorMessageSpan = this.element.querySelector(
      "[data-cmp-hook-form-text='error-msg']"
    );

    if (
      !this.constraintMessage &&
      !this.requiredMessage &&
      this.input.required
    ) {
      this.errorMessageSpan.innerText = "This field is required.";
    }
  }

  addEventListeners(): void {
    this.parentForm.addEventListener(
      "submit",
      this.validateRequired.bind(this)
    );

    if (this.input.required) {
      this.input.addEventListener("focusout", this.validateRequired.bind(this));
      if (this.inputType === "email") {
        const emailPattern = /[^@]+@[^@]+\.[a-zA-Z]{2,}/;
        // Set the pattern attribute directly with the regular expression
        this.input.setAttribute("pattern", emailPattern.source);
      }
    }

    if (this.confirmationField) {
      if (!this.input.required) {
        this.input.addEventListener(
          "focusout",
          this.validateRequired.bind(this)
        );
      }
      this.confirmationField.addEventListener(
        "focusout",
        this.validateRequired.bind(this)
      );
    }
  }

  // Validates, checks constraints and issues error messages for fields that are required
  validateRequired(): void {
    if (this.input.offsetParent === null) {
      return;
    }

    if (!this.input.checkValidity() && this.input.required) {
      console.debug(`FormText validity failed for ${this.input.id}`);
      this.errorMessageSpan.innerText =
        this.requiredMessage || this.errorMessageSpan.innerText;

      // show constraint message if field is not blank
      if (
        this.constraintMessage != null &&
        this.input.value.trimLeft().length > 0
      ) {
        this.errorMessageSpan.innerText = this.constraintMessage;
      }

      this.updateInputState("invalid");

      return;
    }

    if (!this.constraintsValid() && this.input.required) {
      this.updateInputState("invalid");
      return;
    }

    this.updateInputState("clear");

    if (this.shouldConfirm()) {
      if (this.confirmationMatches()) {
        this.updateInputState("confirmed", true);
      } else {
        this.errorMessageSpan.innerText = this.confirmationMessage;
        this.updateInputState("invalid", true);
      }
    }
  }

  constraintsValid(): boolean {
    if (!this.constraintMessage) {
      return true;
    }

    if (this.inputType == "email") {
      const email = this.input.value;
      const matchResult = email.match(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      );
      const emailValid =
        matchResult !== null &&
        matchResult.length > 0 &&
        email == matchResult[0];

      if (!emailValid) {
        this.errorMessageSpan.innerText = this.constraintMessage;
        this.errorMessageSpan.classList.add(Classnames.displayBlock);

        return false;
      }
    }

    if (this.inputType == "tel") {
      const tel = this.input.value;
      const matchResult = tel.match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/);
      const telValid =
        matchResult !== null && matchResult.length > 0 && tel == matchResult[0];

      if (!telValid) {
        this.errorMessageSpan.innerText = this.constraintMessage;
        this.errorMessageSpan.classList.add(Classnames.displayBlock);

        return false;
      }
    }

    return true;
  }

  shouldConfirm(): boolean {
    const inputValue = this.input.value.trim();
    const inputHasValue = inputValue.length > 0;
    if (!this.confirmationField) {
      return false;
    }

    const confValue = this.confirmationField.value.trim();
    const confHasValue = confValue.length > 0;

    return inputHasValue && confHasValue;
  }

  confirmationMatches(): boolean {
    return this.input.value.trim() === this.confirmationField.value.trim();
  }

  updateInputState(state: string, includeConfirmation?: boolean) {
    const handleConfField = this.confirmationField && includeConfirmation;

    this.inputWrapper?.classList.remove(
      Classnames.invalidInput,
      Classnames.confirmedInput
    );
    this.input.classList.remove(
      Classnames.invalidInput,
      Classnames.confirmedInput
    );
    this.errorMessageSpan.classList.remove(Classnames.displayBlock);

    if (handleConfField) {
      this.confirmationField.classList.remove(
        Classnames.invalidInput,
        Classnames.confirmedInput
      );
      this.confirmationField.parentElement.classList.remove(
        Classnames.invalidInput,
        Classnames.confirmedInput
      );
    }

    switch (state) {
      case "invalid":
        this.inputWrapper?.classList.add(Classnames.invalidInput);
        this.input.classList.add(Classnames.invalidInput);
        this.errorMessageSpan.classList.add(Classnames.displayBlock);

        if (handleConfField) {
          this.confirmationField.parentElement.classList.add(
            Classnames.invalidInput
          );
          this.confirmationField.classList.add(Classnames.invalidInput);
        }
        break;
      case "confirmed":
        this.inputWrapper?.classList.add(Classnames.confirmedInput);
        this.input.classList.add(Classnames.confirmedInput);

        if (handleConfField) {
          this.confirmationField.parentElement.classList.add(
            Classnames.confirmedInput
          );
          this.confirmationField.classList.add(Classnames.confirmedInput);
        }
        break;
    }
  }
}

export { FormText };
