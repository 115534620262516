interface FormValues {
  FirstName: string;
  LastName: string;
  PersonMailingStreet: string;
  PersonMailingCity: string;
  PersonMailingState: string;
  PersonMailingPostalCode: string;
  PersonMailingCountry: string;
  Phone: number;
  FirstColor: string;
  SecondColor: string;
  ReasonWhy: string;
  Channel: string;
  PersonEmail: string;
  photoOfWallContent: Record<string, string>;
  receipt1Content: Record<string, string>;
  receipt2Content: Record<string, string>;
}

class LYCG {
  // Elements
  container: HTMLFormElement;

  private endpoint: string;
  private successPage: string;
  private submitBtn: HTMLButtonElement;
  private imageInputs: NodeListOf<HTMLInputElement>;
  private images: Record<string, ArrayBuffer | string>;

  constructor(component: HTMLFormElement) {
    this.container = component;
    this.images = {};
    this.submitBtn = this.container.querySelector('button[type="submit"]');

    if (!this.container) {
      return null;
    }

    const data = this.container.dataset;

    this.endpoint = data.endpoint;
    this.successPage = data.successPage;
    this.imageInputs = this.container.querySelectorAll(
      "[data-cbg-cmp-hook-image-upload='imageupload-input']"
    );
    this.registerEventHandlers();
  }

  private registerEventHandlers() {
    this.container.addEventListener("submit", this.lycgDataToPost.bind(this));
    this.imageInputs.forEach((cb) => {
      cb.addEventListener("change", this.getBase64.bind(this));
    });
  }

  private getBase64(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const fileTypeMap = {
        photoOfWallContent: "wallPhoto",
        receipt1Content: "receipt1",
        receipt2Content: "receipt2",
      };
      const fileType = fileTypeMap[event.target.name];
      this.images[fileType] = reader.result
        .toString()
        .replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
      console.debug({ images: this.images, fileType, name: event.target.name });
    };
    reader.onerror = function (error) {
      console.debug("Error: ", error);
    };
  }

  private beginLoading() {
    console.debug("beginning loading");
    document.body.style.cursor = "wait";
    if (this.submitBtn) {
      this.submitBtn.disabled = true;
    }
  }

  private finishLoading() {
    console.debug("finsh loading");
    document.body.style.cursor = "";
    if (this.submitBtn) {
      this.submitBtn.disabled = false;
    }
  }

  private lycgDataToPost(event: Event): boolean {
    event.preventDefault();
    this.beginLoading();

    if (!this.container.checkValidity()) {
      this.finishLoading();
      return false;
    }

    const lycgFields = [
      "FirstName",
      "LastName",
      "PersonMailingStreet",
      "PersonMailingCity",
      "PersonMailingCountry",
      "PersonMailingState",
      "PersonMailingPostalCode",
      "Phone",
      "FirstColor",
      "ReasonWhy",
      "SecondColor",
      "Channel",
      "PersonEmail",
      "photoOfWallContent",
      "receipt1Content",
      "receipt2Content",
    ];

    const formValues: FormValues = {
      FirstName: "",
      LastName: "",
      PersonMailingStreet: "",
      PersonMailingCity: "",
      PersonMailingState: "",
      PersonMailingPostalCode: "",
      PersonMailingCountry: "",
      Phone: 0,
      FirstColor: "",
      SecondColor: "",
      ReasonWhy: "",
      Channel: "",
      PersonEmail: "",
      photoOfWallContent: {},
      receipt1Content: {},
      receipt2Content: {},
    };

    for (const field of lycgFields) {
      const formField: HTMLInputElement = this.container.querySelector(
        `[id=${field}]`
      );

      if (formField) {
        if (formField.value) {
          formValues[field] = formField.value;
        } else if (formField.classList.contains("imageupload-container")) {
          const image: HTMLInputElement = formField.querySelector("input");
          const uploadedImageName = formField.querySelector(
            '[data-cbg-cmp-hook-image-upload="imageupload-filename"]'
          );
          const imageName = uploadedImageName.innerHTML;

          const fileTypeMap = {
            photoOfWallContent: "wallPhoto",
            receipt1Content: "receipt1",
            receipt2Content: "receipt2",
          };
          const fileType = fileTypeMap[field];
          formValues[field] = {
            fileName: imageName,
            fileContent: this.images[fileType],
            fileType,
          };
        }
      }
    }

    const dataforLycg = {
      caseIdRequest: {
        requestInput: {
          Subject: "Valspar Rebate Request",
          FirstName: formValues.FirstName,
          LastName: formValues.LastName,
          PersonMailingStreet: formValues.PersonMailingStreet,
          PersonMailingCity: formValues.PersonMailingCity,
          PersonMailingState: formValues.PersonMailingState,
          PersonMailingPostalCode: formValues.PersonMailingPostalCode,
          PersonMailingCountry: formValues.PersonMailingCountry,
          Phone: formValues.Phone,
          FirstColor: formValues.FirstColor,
          SecondColor: formValues.SecondColor,
          ReasonWhy: formValues.ReasonWhy,
          Channel: formValues.Channel,
          PersonEmail: formValues.PersonEmail,
        },
      },
      fileInput: [
        {
          fileName: formValues["photoOfWallContent"].fileName,
          fileContent: formValues["photoOfWallContent"].fileContent,
          fileType: formValues["photoOfWallContent"].fileType,
        },
        {
          fileName: formValues["receipt1Content"].fileName,
          fileContent: formValues["receipt1Content"].fileContent,
          fileType: formValues["receipt1Content"].fileType,
        },
        {
          fileName: formValues["receipt2Content"].fileName,
          fileContent: formValues["receipt2Content"].fileContent,
          fileType: formValues["receipt2Content"].fileType,
        },
      ],
    };

    this.postData(dataforLycg).then((res) => res);
  }

  private async postData(info): Promise<void> {
    if (!this.endpoint) {
      throw new Error("Endpoint is not set");
      this.finishLoading();
      return;
    }

    const requestData = JSON.stringify(info);
    console.debug({ requestData });

    try {
      const response = await fetch(this.endpoint, {
        method: "POST",
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          "Content-Type": "application/json",
        },
        body: requestData,
      });

      const data = await response.text();

      console.debug("LYCG response:", data);
      if (response.ok || (response.status >= 200 && response.status < 400)) {
        this.container.reset();
        if (this.successPage) {
          location.href = this.successPage;
        } else {
          this.finishLoading();
        }
      } else {
        this.finishLoading();
        console.debug("Response was not ok");
      }
    } catch (err) {
      this.finishLoading();
      throw err;
    }
  }
}

export { LYCG };
