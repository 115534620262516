import { State } from "../../enum/state";

enum Selectors {
  hook = "data-cmp-hook-filtertoggle",
}

enum ToggleAction {
  show,
  hide,
}

class FieldToggle {
  component: HTMLElement;
  dropdown: HTMLElement;

  constructor(component: HTMLElement) {
    // Elements
    this.component = component;
    if (!this.component) {
      return null;
    }
    this.dropdown = this.component.querySelector(
      `[${Selectors.hook}="dropdown"]`
    );

    this.registerEventHandlers();
  }

  private hideInitialHiddenFields() {
    const inputs: NodeListOf<HTMLInputElement> =
      this.component.querySelectorAll("input, textarea, select");

    inputs.forEach((element) => {
      // make hidden required elements
      if (element.name == "country2" || element.name == "country3") {
        console.debug(element);
        console.debug({ reqd: element.required, offset: element.offsetParent });
      }
      if (
        (!element.closest(".field-toggle-parsys.active") &&
          element.closest(".field-toggle-parsys")) ||
        element.closest(".cookie-aware-fragment-container[hidden]")
      ) {
        element.dataset.wasrequired = "true";
        element.hidden = true;
        element.disabled = true;
      }
    });
  }

  private toggleRequiredStateOfHiddenFields(el: Element, action: ToggleAction) {
    const inputs: NodeListOf<HTMLInputElement> = el.querySelectorAll(
      "input, textarea, select"
    );

    inputs.forEach((element) => {
      // hide elements
      if (
        action == ToggleAction.hide ||
        element.closest(".cookie-aware-fragment-container[hidden]")
      ) {
        element.hidden = true;
        element.disabled = true;
      }

      // re-present elements
      if (
        action == ToggleAction.show &&
        !element.closest(".cookie-aware-fragment-container[hidden]")
      ) {
        element.hidden = false;
        element.disabled = false;
      }
    });
  }

  private registerEventHandlers() {
    if (this.dropdown) {
      this.dropdown.addEventListener("change", (event) => {
        // Get the value of the dropdown.
        const target = <HTMLSelectElement>event.target;
        const optionNode: HTMLOptionElement = target.querySelector(
          `[value="${target.value}"]`
        );
        const parsysId = optionNode.dataset.parsysId || "";

        // Get the matching parsys element.
        const parsys = this.component.querySelector(
          `[data-cmp-hook-filter-toggle-id=${parsysId}]`
        );

        // Hide all of the other parsys elements.
        const parsysContainers = this.component.querySelectorAll(
          ".field-toggle-parsys"
        );
        parsysContainers.forEach((container: HTMLElement) => {
          container.classList.remove(State.ACTIVE);
          this.toggleRequiredStateOfHiddenFields(container, ToggleAction.hide);
        });

        // Show the matching parsys element.
        parsys.classList.add(State.ACTIVE);
        this.toggleRequiredStateOfHiddenFields(parsys, ToggleAction.show);
      });
    }

    document.addEventListener("DOMContentLoaded", (event) => {
      this.hideInitialHiddenFields();
    });
  }
}

export { FieldToggle };
