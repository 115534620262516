import { Cookie, Utils } from "../../site/scripts/utils";
import { Message } from "../../site/scripts/Message";
import { CartAPI } from "../cart/AskVal";
import { FavoriteCTA } from "./FavoriteCTA";

interface FavoriteColorItem {
  mdmColorId?: string;
  cartId: number | null;
  channelId: string;
  collection?: string;
  collectionData?: string;
  collectionName?: string;
  colorId: string;
  colorName: string;
  colorNumber: string;
  colorFamily: string;
  colors?: string[];
  colorsInCollectionText?: string;
  hex?: string;
  hexColor?: string;
  href?: string;
  id?: number;
  localeId?: string;
  paintSampleUrl?: string;
  tagEmblem?: string;
  tags?: string;
  timestamp?: number;
  mdmId?: string;
  swatchUrl?: string;
  mdmType?: string;
  transparency?: string;
  associatedProducts?: Record<string, string>[];
}

// Only adding the attributes we are using.
// Add more as you need more
export interface ProductT {
  productId: string;
  productName: string;
}

class FavoritesController {
  cartId: null;
  postToAPI = false;
  persistenceKey = "favorites";
  linksKey = "favoritesLinks";
  timestampKey = "favoritesTimestamps";
  tagsKey = "favoritesTags";
  favorites: FavoriteColorItem[];
  links: Record<string, string>;
  timestamps: Record<string, number>;
  tags: Record<string, string>;
  brand: string;
  favoritesMdmVersion: boolean;

  constructor() {
    this.brand = Utils.getCbgBrand();
    this.favorites = this.read();
    const element = document.querySelector(
      '[data-cbg-cmp="favorites"]'
    ) as HTMLElement;

    //Hide Cart if Minwax
    const cartElement = document.querySelector<HTMLElement>(
      '[data-cbg-cmp="cart"]'
    );
    this.brand === "minwax" &&
      cartElement?.parentElement?.classList.add("hide-cart");

    this.favoritesMdmVersion = element?.dataset?.favoritesMdmVersion === "true";
    this.updateFavoriteCTAs();
    this.checkForCartId();
  }

  /*
   Initializes a CTA for any active favorite.
   */
  updateFavoriteCTAs(): void {
    (this.favorites || []).map((favorite) => {
      const id = FavoritesController.getFavoriteId(
        favorite,
        this.favoritesMdmVersion
      );
      //Check for MDM only for favoritesMdmVersion alone
      const target = this.favoritesMdmVersion
        ? `.cbg-favorite-cta[data-mdmid="${id}"]`
        : `.cbg-favorite-cta[data-fav-color-id="${id}"]`;
      const elements = document.querySelectorAll(
        target
      ) as NodeListOf<HTMLElement>;
      elements.forEach((element) => {
        if (element && element.dataset.cmpInit !== "true") {
          element.dataset.cmpInit = "true";
          new FavoriteCTA(<HTMLElement>element, favorite);
        }
      });
    });
  }

  setPostToApi(postToApiCookie: string) {
    this.postToAPI = postToApiCookie === "use-api";
  }

  checkForCartId() {
    const cartApiResponse = Cookie.get("cart-api");
    if (!this.cartId && cartApiResponse) {
      this.cartId = JSON.parse(cartApiResponse).cartId || null;
    }
  }

  refreshFavorites() {
    return JSON.parse(Cookie.get("cart-api")).favorites;
  }

  static getFavoriteId(favorite: FavoriteColorItem, brand?: boolean): string {
    const favItem = brand
      ? favorite.mdmId || favorite.mdmColorId
      : favorite.collection || favorite.colorNumber;
    return favItem;
  }

  addAndPersist(favorite: FavoriteColorItem) {
    const favoriteToSave = {
      collection: favorite.collection,
      collectionData: favorite.collectionData,
      collectionName: favorite.collectionName,
      channelId: favorite.channelId,
      colorId: favorite.colorId,
      colorName: favorite.colorName,
      colorNumber: favorite.colorNumber,
      colorFamily: favorite.colorFamily,
      id: favorite.id,
      mdmId: favorite.mdmId,
      swatchUrl: favorite.swatchUrl,
      cartId: null,
      hexColor: favorite.hex,
      href: favorite.href,
      tagEmblem: null,
      favorite: true,
      mdmType: favorite.mdmType,
      transparency: favorite.transparency,
    };
    // Push the incoming favorite to the global array.
    this.favorites.push(favoriteToSave);
    // Save the supplemental data for timestamps, tags, and links.
    const id = FavoritesController.getFavoriteId(
      favorite,
      this.favoritesMdmVersion
    );
    if (typeof id !== "undefined") {
      this.tags[id] = favorite.tags || "";
      this.links[id] = favorite.href || "";
      this.timestamps[id] = favorite.timestamp || new Date().getTime();
    }

    // Persist the data.
    this.persistData();
  }

  add(favorite: FavoriteColorItem) {
    if (
      typeof favorite.collection === "undefined" &&
      typeof favorite.colorName === "undefined"
    ) {
      return;
    }

    const favItems = JSON.parse(
      window.localStorage.getItem(this.persistenceKey) || "[]"
    );
    const count = favItems.length;

    if (this.brand === "minwax" && count >= 8) {
      Utils.msg.publish(Message.addFavoritesErrorResponse, {
        colorName: "You’ve reached the maximum number of favorites allowed",
      });
      return;
    }

    this.addAndPersist(favorite);
    // Publish message a message that a favorite was added.
    Utils.msg.publish(Message.addToFavorites, favorite);
    Utils.msg.publish(Message.addFavoritesResponse, this.favorites);

    const itemID: string =
      this.brand === "minwax"
        ? favorite.mdmId
        : this.brand === "dutchboy"
        ? favorite.mdmId
        : favorite.colorId; //triggering build
    if (this.postToAPI) {
      try {
        const isColorCollection =
          favorite.colorId !== favorite.collection ||
          favorite.collection === "COTY";

        CartAPI.addFavorite(
          itemID,
          isColorCollection ? "color" : "collection",
          favorite.channelId,
          favorite.localeId
        );
      } catch {
        console.warn("Trouble adding Favorite...");
      }
    }
  }

  remove(colorId: string) {
    // This takes the colorID of the item to be removed, finds it in the array of favorites, gets the ID associated with it
    // which is unique each call, and sends it back in the remove call.
    const indexOfFaveWithMatchingColorId = this.favorites.findIndex((fave) => {
      return (
        fave.mdmId === colorId || //MdmID
        fave.mdmColorId === colorId || // MDM Id
        fave.colorId === colorId || // Color
        fave.collectionName === colorId // Collection
      );
    });

    if (indexOfFaveWithMatchingColorId > -1) {
      const removedFavorite =
        this.favorites[indexOfFaveWithMatchingColorId] || {};
      this.favorites.splice(indexOfFaveWithMatchingColorId, 1);

      this.persistData();
      // Publish messages.
      Utils.msg.publish(Message.removeFromFavorites, removedFavorite);
      Utils.msg.publish(Message.removeFromFavoritesResponse, this.favorites);
    }

    if (this.postToAPI) {
      // Find the index of the clicked item and grab its ID to send to the favoritesAPI along with the token.,
      const apiFavorites = this.refreshFavorites();
      const clickedIndex = apiFavorites.findIndex((favoriteItem) => {
        return (
          favoriteItem.mdmId === colorId ||
          favoriteItem.mdmColorId === colorId || // MDM Id
          favoriteItem.colorNumber === colorId || // Color
          favoriteItem.collection === colorId // Collection
        );
      });
      try {
        CartAPI.removeFromFavorites(apiFavorites[clickedIndex].id);
      } catch {
        console.warn("Trouble removing favorite");
      }
    }
  }

  private persistData() {
    // Save data for the favorites array.
    window.localStorage.setItem(
      this.persistenceKey,
      JSON.stringify(this.favorites)
    );

    // Save data for when a favorite was saved.
    window.localStorage.setItem(
      this.timestampKey,
      JSON.stringify(this.timestamps)
    );

    // Save data for the favorite's link.
    window.localStorage.setItem(this.linksKey, JSON.stringify(this.links));

    // Save data for the favorite's tags.
    window.localStorage.setItem(this.tagsKey, JSON.stringify(this.tags));

    // Notify React that localStorage has been updated
    const event = new Event("LOCAL_FAVORITE_UPDATE");
    window.dispatchEvent(event);
  }

  read() {
    let favorites = [];
    const cartCookie = Cookie.get(CartAPI.cartCookie);
    if (this.postToAPI) {
      if (cartCookie) {
        favorites = JSON.parse(cartCookie).favorites;
      } else {
        CartAPI.getCart().then(() => {
          try {
            favorites = JSON.parse(Cookie.get(CartAPI.cartCookie)).favorites;
          } catch (e) {
            console.warn(
              e,
              "A problem occurred while trying to retrieve Favorites. If testing, please turn off VPN and try again from an external dispatch site."
            );
          }
        });
      }
    } else {
      // Read from the Favorites data store and return any favorites from there.
      favorites = JSON.parse(
        window.localStorage.getItem(this.persistenceKey) || "[]"
      );
    }
    if (cartCookie) {
      favorites = JSON.parse(cartCookie).favorites;
    }

    // Fetch the supplemental data for timestamps, tags, and links and add it to each item.
    this.timestamps = JSON.parse(
      window.localStorage.getItem(this.timestampKey) || "[]"
    );
    this.links = JSON.parse(window.localStorage.getItem(this.linksKey) || "[]");
    this.tags = JSON.parse(window.localStorage.getItem(this.tagsKey) || "[]");
    favorites = favorites.map((favorite) => {
      const id = FavoritesController.getFavoriteId(
        favorite,
        this.favoritesMdmVersion
      );
      const timestamp = this.timestamps[id] || 0;
      const href = this.links[id] || "";
      const tags = this.tags[id] || "";

      // Extend the favorite with timestamp, href, and tags properties.
      return Object.assign(favorite, { timestamp, href, tags });
    });

    // Sort favorites by time saved
    favorites.sort((a, b) => (b.timestamp || 0) - (a.timestamp || 0));
    return favorites;
  }

  count() {
    const favorites = this.read();
    return favorites.length;
  }
}

const FavoritesAPI = new FavoritesController();

export { FavoritesAPI, FavoriteColorItem };
