import { State } from "../../enum/state";
import { Cpra } from "../../site/scripts/cpra";
import { Utils } from "../../site/scripts/utils";

enum Classnames {
  itemWithSubmenu = ".cmp-navigation__item--level-0.has-children",
  navLink = ".cmp-navigation__item-link",
}

enum Level {
  main = "main",
  subnav = "subnav",
}

enum Selector {
  hook = "data-cmp-hook-navigation",
}

class Navigation {
  containerId: string;

  // DOM Element References
  container: HTMLElement;
  menuButton: HTMLButtonElement;
  navElement: HTMLElement;
  submenuBackButtons: NodeListOf<HTMLDivElement>;
  submenuParentLinks: NodeListOf<HTMLAnchorElement>;
  cpra: Cpra;
  currentBrand: string;
  favIcon: HTMLElement;

  constructor(component: HTMLElement) {
    if (!component) return;

    this.container = component;
    this.containerId = component.getAttribute("id");
    this.navElement = this.container.querySelector("nav");
    this.menuButton = this.container.querySelector(
      `[${Selector.hook}="menu-button"]`
    );
    this.submenuBackButtons = this.container.querySelectorAll(
      `[${Selector.hook}="subnav-back-button"]`
    );
    this.submenuParentLinks = this.container.querySelectorAll(
      `${Classnames.itemWithSubmenu} > ${Classnames.navLink}`
    );
    this.favIcon = document.querySelector(
      ".search-zone .favorites .cbg-cmp-favorites"
    );
    this.currentBrand = Utils.getCbgBrand();
    this.cpra = new Cpra();

    this.registerEventHandlers();
  }

  private closeMainNav() {
    document.body.classList.remove(State.NAV_OPEN);
    this.container.classList.remove(State.NAV_OPEN);
  }

  private closeSubNav() {
    this.container.classList.remove(State.SUBNAV_OPEN);
    document.body.classList.remove(State.SUBNAV_OPEN);
  }

  private deactivateActiveTopLevelLinks() {
    const selector = `${Classnames.itemWithSubmenu}.${State.ACTIVE}`;
    const topLevelLinks = document.querySelectorAll(selector);

    topLevelLinks.forEach((el) => {
      el.classList.remove(State.ACTIVE);
    });
  }

  private handleBackButtonClick(event: MouseEvent) {
    event.preventDefault();

    this.closeSubNav();
    this.deactivateActiveTopLevelLinks();
  }

  private collapse() {
    this.closeSubNav();
    this.closeMainNav();
    this.deactivateActiveTopLevelLinks();
  }

  private expandNavLevel(navState: State) {
    document.body.classList.add(navState);
    this.container.classList.add(navState);
  }

  private expand(type: Level) {
    switch (type) {
      case Level.main:
        this.expandNavLevel(State.NAV_OPEN);
        break;

      case Level.subnav:
        this.expandNavLevel(State.SUBNAV_OPEN);
        break;

      default:
        break;
    }

    if (this.navElement) this.navElement.scrollTop = 0;
  }

  private handleMenuButtonClick(event: MouseEvent) {
    event.preventDefault();

    if (this.container.classList.contains(State.NAV_OPEN)) {
      this.collapse();
    } else {
      this.expand(Level.main);
    }
  }

  private handleSubmenuParentLinkClick(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();

    const link = event.target as HTMLAnchorElement;
    const parentLink = link.closest(`${Classnames.itemWithSubmenu}`);
    const isActive = parentLink.classList.contains(State.ACTIVE);

    // Remove active links
    this.deactivateActiveTopLevelLinks();

    if (!isActive) {
      parentLink.classList.add(State.ACTIVE);
      this.expand(Level.subnav);
    } else {
      this.closeSubNav();
    }
  }

  private handleOutsideClick(event: MouseEvent) {
    const target = event.target as HTMLElement;

    //  When click detected outside of nav or the overlay is clicked, collapse the nav.
    const clickedOutside = !target.closest(`#${this.containerId}`);
    const clickedOverlay = target.getAttribute(Selector.hook) === "overlay";

    if (clickedOutside || clickedOverlay) {
      this.collapse();
    }
  }

  private keyHandler(evt: KeyboardEvent) {
    if (evt.key && evt.key === "Escape") {
      this.collapse();
    }
  }

  private favNavigateClick() {
    if (this.currentBrand === "minwax") {
      this.favIcon.querySelector("a").click();
    }
  }

  private registerEventHandlers() {
    // Local Handlers Dummy
    this.submenuBackButtons.forEach((button) => {
      button.addEventListener("click", this.handleBackButtonClick.bind(this));
    });
    this.menuButton.addEventListener(
      "click",
      this.handleMenuButtonClick.bind(this)
    );
    this.submenuParentLinks.forEach((link) => {
      link.addEventListener(
        "click",
        this.handleSubmenuParentLinkClick.bind(this)
      );
    });

    //Click event on the fav Dummy
    this.favIcon?.addEventListener("click", this.favNavigateClick.bind(this));

    // Global Handlers
    window.addEventListener("click", this.handleOutsideClick.bind(this));
    window.addEventListener("keydown", this.keyHandler.bind(this));
  }
}

export { Navigation };
