import { Modal } from "./Modal";
import { isUndefined } from "webpack-merge/dist/utils";

enum Classnames {
  modalActive = "modal__active",
}

enum Selector {
  modal = '[data-cbg-cmp="modal"]',
}

class ModalButton {
  // Elements
  element: HTMLElement;
  videoId: string;

  constructor(component: HTMLElement) {
    this.element = component;
    this.videoId = this.element.dataset.videoId;

    if (!this.element) {
      return;
    }

    this.registerEventHandlers();
  }

  private registerEventHandlers() {
    this.element.addEventListener("click", (evt) => {
      let modalSelector =
        (this.element && this.element.dataset.opensModal) || "";
      modalSelector = modalSelector.replace(/^(\s+)/g, "");

      if (modalSelector.indexOf("#") === -1) {
        return;
      }

      const modalContainer = document.querySelector(modalSelector);
      if (modalContainer) {
        const modalEl = modalContainer.querySelector(Selector.modal);
        const modalInstance = new Modal(<HTMLElement>modalEl);
        const youtubeVideoId = this.videoId;
        if (modalInstance) {
          if (!isUndefined(youtubeVideoId) && youtubeVideoId.length > 0) {
            const currentURL = modalInstance.videoPlayer.getAttribute("src");
            const indexOfQuestionMark = currentURL.indexOf("?");
            const tempUrl = currentURL.slice(0, indexOfQuestionMark);
            const lastIndexOfBackslash = tempUrl.lastIndexOf("/");
            const valueToBeReplaced = tempUrl.slice(
              lastIndexOfBackslash + 1,
              indexOfQuestionMark
            );
            modalInstance.videoPlayer.setAttribute("src", "");
            const newUrl = currentURL.replace(
              valueToBeReplaced,
              youtubeVideoId
            );
            modalInstance.videoPlayer.setAttribute("src", newUrl);
          }
          modalInstance.openModal();
        }
      }
    });
  }
}

export { ModalButton };
