import { Message } from "../../site/scripts/Message";
import { Cookie, Utils } from "../../site/scripts/utils";

enum Classnames {
  displayNone = "display-none",
  iconAdd = "icon-add",
  iconRemove = "icon-remove",
  addFavStyle = "fav-add",
}

enum Cookiename {
  cart = "cart",
}

enum channelIds {
  "cbg:lowes" = 1001,
  "cbg:independent-retailer" = 1003,
  "cbg:hgsw" = 1006,
}

enum Selector {
  element = "data-component-id",
  hook = "data-cbg-cmp-hook-colorDetail",
}

class ColorDetail {
  //Elements
  component: HTMLElement;
  chipButton: HTMLElement;
  chipTextRemove: HTMLElement;
  chipTextAdd: HTMLElement;

  tagEmblem: string;
  href: string;
  colorNumber: string;
  collection: string;
  tagsAsAString: string;

  // Properties
  channelId: number;
  colorId: string;
  chipCookie: string;
  tags: string;

  constructor(component: HTMLElement) {
    this.component = component;

    if (!this.component) {
      return;
    }

    this.chipCookie = Cookie.get(Cookiename.cart);
    this.colorId = this.component.dataset.colorId;
    this.tags = this.component.dataset.colorTags;
    this.channelId = this.channelId = this.getChannelId(this.tags);

    this.initializeUI();
    this.registerEventHandlers();
    this.registerMessageSubscribers();

    if (this.chipCookie) {
      this.updatedCta();
    }
  }

  private getChannelId(tags: string): number {
    // if no channelId can be determined, set to 0
    if (!tags) {
      return 0;
    }

    const key = Object.keys(channelIds).find((key) => tags.includes(key));

    return channelIds[key];
  }

  private initializeUI(): void {
    this.chipButton = this.component.querySelector(
      `[${Selector.hook}="chip-btn"]`
    );

    this.chipTextAdd = this.component.querySelector(
      `[${Selector.hook}="chip-text-add"]`
    );

    this.chipTextRemove = this.component.querySelector(
      `[${Selector.hook}="chip-text-remove"]`
    );
  }

  private registerEventHandlers(): void {
    if (this.chipButton) {
      this.chipButton.addEventListener(
        "click",
        this.updateColorChip.bind(this)
      );
    }
  }

  private registerMessageSubscribers(): void {
    // Subscribe to the color chip update publisher.
    Utils.msg.subscribe(
      Message.updateColorChipState,
      this.updatedCta.bind(this)
    );
  }

  private getUpdatedCookie() {
    const cookie = Cookie.get(Cookiename.cart);
    const colorChipArray = JSON.parse(cookie);
    return colorChipArray;
  }

  private updatedCta(): void {
    const colorId = this.colorId;
    const channelId = this.channelId;
    const chipTextAdd = this.chipTextAdd;
    const chipTextRemove = this.chipTextRemove;

    if (chipTextRemove.classList.contains(Classnames.displayNone)) {
      const cookieArray = this.getUpdatedCookie();
      const itemToAdd = cookieArray.findIndex((i) => {
        return i.colorNumber === colorId;
      });
      if (itemToAdd > -1) {
        const itemToADD = cookieArray.filter((i) => {
          return i.colorNumber === colorId;
        });
        const colorID = itemToADD[0].id;
        chipTextAdd.classList.add(Classnames.displayNone);
        chipTextRemove.classList.remove(Classnames.displayNone);
        Utils.msg.publish(Message.addToCart, {
          colorNumber: `${colorID}`,
          channelId: channelId,
        });
      }
    } else {
      const cookieArray = this.getUpdatedCookie();
      const itemToRemove = cookieArray.filter((i) => {
        return i.colorNumber === colorId;
      });

      const colorID = itemToRemove[0].id;
      chipTextAdd.classList.remove(Classnames.displayNone);
      chipTextRemove.classList.add(Classnames.displayNone);
      Utils.msg.publish(Message.removeFromCart, {
        id: `${colorID}`,
      });
    }
  }

  private updateColorChip(): void {
    const colorId = this.colorId;
    const channelId = this.channelId;
    const chipTextAdd = this.chipTextAdd;
    const chipTextRemove = this.chipTextRemove;
    if (chipTextRemove.classList.contains(Classnames.displayNone)) {
      chipTextAdd.classList.add(Classnames.displayNone);
      chipTextRemove.classList.remove(Classnames.displayNone);
      Utils.msg.publish(Message.addToCart, {
        colorNumber: `${colorId}`,
        channelId: channelId,
      });
    } else {
      const cookieArray = this.getUpdatedCookie();
      const itemToRemove = cookieArray.filter((i) => {
        return i.colorNumber === colorId;
      });

      const colorID = itemToRemove[0].id;
      chipTextAdd.classList.remove(Classnames.displayNone);
      chipTextRemove.classList.add(Classnames.displayNone);
      Utils.msg.publish(Message.removeFromCart, {
        id: `${colorID}`,
      });
    }
  }
}

export { ColorDetail };
