import { Cookie, Utils } from "../../site/scripts/utils";
import { Message } from "../../site/scripts/Message";

enum Selector {
  element = "data-component-id",
  hook = "data-hook-content-drawer",
}

class ContentDrawer {
  cookieLabel: string;
  cookieValueToTest: string;
  showFragmentContainer: boolean;
  fragmentContainer: HTMLElement;
  container: HTMLElement;
  showMoreButton: HTMLElement;
  showLessButton: HTMLElement;
  showMoreContainer: HTMLElement;
  showLessContainer: HTMLElement;
  component: HTMLElement;

  constructor(component: HTMLElement) {
    // Elements
    this.component = component;

    if (!this.component) {
      return;
    }
    this.showFragmentContainer = false;

    this.cookieLabel = this.component.dataset.cmpCookieLabel;
    this.cookieValueToTest = this.component.dataset.cmpCookieValue;
    this.fragmentContainer = this.component.querySelector(
      ".cookie-aware-fragment-container"
    );
    this.fragmentContainer.hidden = true;
    this.showMoreButton =
      this.component.querySelector(`[${Selector.hook}="show-more"]`) || null;
    this.showLessButton =
      this.component.querySelector(`[${Selector.hook}="show-less"]`) || null;
    this.showMoreContainer =
      this.component.querySelector(
        `[${Selector.hook}="show-more-container"]`
      ) || null;
    this.showLessContainer =
      this.component.querySelector(
        `[${Selector.hook}="show-less-container"]`
      ) || null;

    if (!this.component) {
      return null;
    }
    this.registerMessageSubscribers();
    this.registerEventHandlers();
    this.retrieveCookie();
    this.showOrHideContentContainer();
  }

  private registerEventHandlers() {
    if (this.showMoreButton) {
      this.showMoreButton.addEventListener(
        "click",
        ((e) => {
          e.stopPropagation();
          this.showLessContainer.hidden = false;
          this.showLessContainer.classList.add("open");
          this.showLessContainer.classList.remove("closed");

          this.showMoreButton.hidden = true;
          this.showMoreButton.classList.add("hidden");
          this.showLessButton.hidden = false;
          this.showLessButton.classList.remove("hidden");
        }).bind(this)
      );
    }

    if (this.showLessButton) {
      this.showLessButton.addEventListener(
        "click",
        ((e) => {
          e.stopPropagation();
          this.showLessContainer.hidden = true;
          this.showLessContainer.classList.add("closed");
          this.showLessContainer.classList.remove("open");

          this.showLessButton.hidden = true;
          this.showLessButton.classList.add("hidden");
          this.showMoreButton.hidden = false;
          this.showMoreButton.classList.remove("hidden");
        }).bind(this)
      );
    }
  }

  retrieveCookie() {
    const cookie = Cookie.get(this.cookieLabel);
    if (cookie == undefined) {
      this.showFragmentContainer = true;
    } else {
      if (cookie === this.cookieValueToTest) {
        this.showFragmentContainer = true;
      } else {
        const mode = Cookie.get("wcmmode");
        if (mode === "edit") {
          this.showFragmentContainer = true;
        } else {
          this.showFragmentContainer = false;
        }
      }
    }
    this.showOrHideContentContainer();
  }

  showOrHideContentContainer() {
    if (this.showFragmentContainer) {
      this.fragmentContainer.hidden = false;
    } else {
      this.fragmentContainer.hidden = true;
    }
  }

  /**
   * Registers subscribers for published messages.
   */
  private registerMessageSubscribers(): void {
    Utils.msg.subscribe(Message.cookieUpdated, (response) => {
      this.updateCookieView();
    });
  }

  updateCookieView() {
    this.retrieveCookie();
  }
}

export { ContentDrawer };
