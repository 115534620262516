class Disclaimer {
  private container: HTMLDivElement;
  private input: HTMLInputElement;
  private parentForm: HTMLFormElement;
  private readonly disclaimerRequired: boolean;
  private errorMessageSpan: HTMLSpanElement;

  constructor(element: HTMLDivElement) {
    this.container = element;
    this.input = this.container.querySelector("input");
    this.parentForm = this.input.form;
    this.errorMessageSpan = this.container.querySelector(
      ".invalid-input-message"
    );

    this.disclaimerRequired =
      this.container.dataset.disclaimerRequired === "true";
    this.registerEventHandlers();
  }

  private registerEventHandlers() {
    this.parentForm.addEventListener(
      "submit",
      this.validateRequired.bind(this)
    );

    this.input.addEventListener("change", this.validateRequired.bind(this));
  }

  private validateRequired() {
    console.debug("validating disclaimer");
    if (this.disclaimerRequired && !this.input.checked) {
      this.showErrorMessage();
      return false;
    }

    this.input.value = "true";

    console.debug({
      required: this.disclaimerRequired,
      checked: this.input.checked,
    });

    console.debug("disclaimer valid");
    this.hideErrorMessage();
    return true;
  }

  private showErrorMessage(): void {
    this.errorMessageSpan.classList.add("display-block");
  }

  private hideErrorMessage(): void {
    this.errorMessageSpan.classList.remove("display-block");
  }
}

export { Disclaimer };
