import { throttle } from "lodash";
import { Cookie, Utils } from "../../site/scripts/utils";
import { Message } from "../../site/scripts/Message";

const RETAILER_PARAM_NAME = "r";
enum Selector {
  siteNav = 'data-cbg-cmp="site-navigation"',
  siteNavVersionTwo = 'data-cbg-cmp="site-navigationV2"',
  hook = "data-cmp-hook-navigator",
  element = "data-component-id",
  dropdown = "data-navigator-dropdown",
  select = "data-cmp-hook-retailer",
}

enum Classnames {
  fixed = "fixed",
  // Global Retailer Specific Classes
  noScroll = "stop-scrolling",
  displayNone = "display-none",
  loading = "loading",
  retailerCta = "global-retailer-cta",
  modalActive = "modal__active",
  flexLoader = "flex-loader",
}

enum Retailer {
  ir = "independent-retailer",
  lowes = "lowes",
}

interface Anchor {
  id: string;
  link: HTMLElement;
  element: HTMLElement;
  percentInView: number;
}

class PageNavigator {
  siteNavHeight: number;

  // Elements
  container: HTMLElement;
  links: Array<HTMLElement>;
  anchors: Array<Anchor>;
  siteNavigation: HTMLElement;
  snapToTop: boolean;
  listMenuIcon: HTMLElement;
  // Global Retailer Specific Elements
  retailerTitle: HTMLElement;
  retailerListContainer: HTMLElement;
  retailerOverlay: HTMLElement;
  retailerList: HTMLElement;
  retailerSelectElement: HTMLElement;
  retailerItems: HTMLElement;
  retailerCta: HTMLElement;
  retailerLoading: HTMLElement;
  chipIcon: HTMLElement;
  cookieCategory: string;
  storedRetailerValue: string;

  // The minimum amount an element must be in view to be considered to be active.
  viewabilityThreshold: number;

  constructor(component: HTMLElement) {
    this.container = component;

    if (!this.container) {
      return;
    }

    // get the cookie category
    this.cookieCategory = this.container.dataset.cookieCategory;

    // check if this is nav version 1
    const navigationVersionOne = document.querySelector(
      `[${Selector.siteNav}]`
    );

    if (
      typeof navigationVersionOne != "undefined" &&
      navigationVersionOne != null
    ) {
      this.siteNavigation = document.querySelector(`[${Selector.siteNav}]`);
    } else {
      this.siteNavigation = document.querySelector(
        `[${Selector.siteNavVersionTwo}]`
      );
    }

    this.snapToTop =
      this.container.dataset && this.container.dataset.snapToTop === "true";
    this.siteNavHeight = this.siteNavigation.getBoundingClientRect().height;

    const links = this.container.querySelectorAll(`[${Selector.hook}="link"]`);

    this.links = Array.prototype.slice.call(links);
    this.anchors = [];
    this.viewabilityThreshold = 5;

    // TODO: Remove valspar brand check when "Preferred Retailer" option is enabled for other brands
    if (Utils.getCbgBrand() === "valspar") {
      this.getRetailerCookieValue();
      // set the cookie as a body class
      const cookieElements = this.storedRetailerValue.split("/");
      document
        .querySelector("body")
        .classList.add(cookieElements[cookieElements.length - 1]);

      this.setRetailerDropownValue(false);
      this.hideButton();
    }

    this.initializeRetailerUI();
    this.getAnchorPositions();
    this.updateActiveLink();
    this.registerEventHandlers();
    this.registerMessageSubscribers();
  }

  private getRetailerQueryParam(): string {
    const params = new URLSearchParams(window.location.search);
    return params.get(RETAILER_PARAM_NAME) || "";
  }

  private getCookieCategory(retailer: string): string {
    const cbgBrand = Utils.getCbgBrand();

    return cbgBrand ? `cbg:${cbgBrand}/cookies/retailer/${retailer}` : null;
  }

  private getRetailerCookieValue() {
    let storedCookie = Cookie.get(this.cookieCategory);
    const retailerQueryParam = this.getRetailerQueryParam();

    switch (retailerQueryParam) {
      case "ir":
        storedCookie = this.getCookieCategory(Retailer.ir);
        break;
      case "lowes":
        storedCookie = this.getCookieCategory(Retailer.lowes);
        break;
      default:
        storedCookie = storedCookie ?? this.getCookieCategory(Retailer.lowes);
    }

    Cookie.set(this.cookieCategory, storedCookie, 730);
    this.storedRetailerValue = storedCookie
      ? storedCookie.match(/[^:]*$/)[0]
      : "";
  }

  public storeCookie(e: Event): void {
    e.preventDefault();

    const eventTarget = e.target as HTMLSelectElement;

    const cookieValue = eventTarget.options[eventTarget.options.selectedIndex];
    let tempcookie = cookieValue.dataset.cookieId;
    if (tempcookie) {
      tempcookie = tempcookie.replace("'", "");
    } else {
      //defaulting to lowes if no cookie found
      tempcookie = this.getCookieCategory(Retailer.lowes);
    }

    Cookie.set(this.cookieCategory, tempcookie, 10000);

    if (Utils.getCbgBrand() === "valspar") {
      // set the cookie as a body class
      const cookieElements = tempcookie.split("/");
      const bodyElement = document.querySelector("body");
      if (cookieElements[cookieElements.length - 1] == Retailer.lowes) {
        bodyElement.classList.remove(Retailer.ir);
        bodyElement.classList.add(Retailer.lowes);
      } else if (cookieElements[cookieElements.length - 1] == Retailer.ir) {
        bodyElement.classList.remove(Retailer.lowes);
        bodyElement.classList.add(Retailer.ir);
      }
    }

    this.setRetailerDropownValue(true);
    Utils.msg.publish(Message.cookieUpdated, { retailer: tempcookie });
  }

  private setRetailerDropownValue(valueSelected) {
    const selectContainer = this.container.querySelector(
      `[${Selector.select}="dropdown"]`
    ) as HTMLSelectElement;
    if (selectContainer) {
      const storedCookie = Cookie.get(this.cookieCategory);
      const storedValue = storedCookie
        ? storedCookie.match(/[^:]*$/)[0] ?? ""
        : "";
      let dataSetElementId: string;
      Array.from(selectContainer.options).forEach(function (
        option_element: HTMLOptionElement
        /* index */
      ) {
        dataSetElementId = option_element.dataset.cookieId;
        if (dataSetElementId) {
          const openModalId = option_element.dataset.opensModal;
          const datasetValue = dataSetElementId.match(/[^:]*$/)[0];
          if (valueSelected && option_element.selected == true) {
            if (openModalId) {
              const gotSomething = document.querySelector(openModalId);
              if (gotSomething) {
                const childNode = gotSomething.querySelector(".cbg-cmp-modal");
                if (childNode) {
                  childNode.classList.add(Classnames.modalActive);
                }
              }
            }
          }
          if (datasetValue === storedValue) {
            option_element.selected = true;
          }
        }
      });
      this.hideButton();
    }
  }

  private registerMessageSubscribers(): void {
    Utils.msg.subscribe(Message.cookieUpdated, (response) => {
      this.setRetailerDropownValue(true);
    });
  }

  /**
   * Initializes the Global Retailer UI properties.
   */
  private initializeRetailerUI() {
    // Global Retailer Properties
    this.listMenuIcon = this.container.querySelector(
      `[${Selector.dropdown}="menu-cta"]`
    );

    this.retailerItems = this.container.querySelector(".global-retailer-items");

    this.retailerSelectElement = this.container.querySelector(
      `[${Selector.select}="dropdown"]`
    );

    this.chipIcon = this.container.querySelector(
      `[${Selector.hook}="chip-icon"]`
    );

    if (this.retailerSelectElement) {
      this.retailerSelectElement.onchange = this.storeCookie.bind(this);
    }
  }

  private percentInView(element: HTMLElement) {
    if (!element) return 0;

    const navHeight = this.container.offsetHeight;

    const elTop = element.offsetTop;
    const elHeight = element.scrollHeight;
    const elBottom = elTop + elHeight;

    const viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );
    // Top of viewport adjusted for nav height
    const adjustedViewTop = window.scrollY + navHeight;
    // viewport height adjusted for navHeight;
    const adjustedViewHeight = viewHeight - navHeight;
    const viewBottom = window.scrollY + viewHeight;

    const totalHeight =
      Math.max(elBottom, viewBottom) - Math.min(elTop, adjustedViewTop);
    const heightDiff = totalHeight - adjustedViewHeight;
    const elementInside = elHeight - heightDiff;
    const percentage =
      elementInside <= 0 ? 0 : (elementInside / elHeight) * 100;

    return percentage;
  }

  /**
   * Loops through each link to associate a matching element.
   */
  private getAnchorPositions() {
    const brand = Utils.getCbgBrand();
    this.links.forEach(
      function (link) {
        const linkHref = link.getAttribute("href");
        let isAnchorLink;

        if (linkHref && linkHref.indexOf) {
          const wheresTheHash = linkHref.indexOf("#");
          if (wheresTheHash != -1) {
            isAnchorLink = true;
          }
          const linkId = wheresTheHash === 0 ? linkHref.substr(1) : linkHref;

          if (!linkId || linkId.trim().length === 0) {
            return;
          }

          if (isAnchorLink) {
            this.anchors.push({
              id: linkId,
              link,
              element: document.querySelector(`#${linkId}`),
            });
          }
        }
      }.bind(this)
    );

    // Add scroll offset to document when "floating" is enabled and anchor links are present
    if (this.snapToTop && this.anchors.length) {
      document.documentElement.style.setProperty(
        "--page-navigator-scroll-top",
        `${this.container.clientHeight}px`
      );
    }
  }

  private updatePositioning() {
    if (window.scrollY >= this.siteNavHeight) {
      if (this.snapToTop) {
        this.container.classList.add(Classnames.fixed);
      }
      const top = this.siteNavigation.getBoundingClientRect().bottom;
      if (top > 0) {
        this.container.style.top = `${top}px`;
      } else {
        this.container.removeAttribute("style");
      }
    } else {
      if (this.snapToTop) {
        this.container.classList.remove(Classnames.fixed);
      }
      this.container.removeAttribute("style");
    }
  }

  private updateActiveLink() {
    this.anchors.forEach((anchor) => {
      anchor.link.parentElement.classList.remove("active");
      anchor.percentInView = this.percentInView(anchor.element);
    });

    try {
      const inViewAnchor = this.anchors.sort((a, b) => {
        return b.percentInView - a.percentInView;
      })[0];

      if (
        inViewAnchor &&
        inViewAnchor.percentInView > this.viewabilityThreshold
      ) {
        inViewAnchor.link.parentElement.classList.add("active");
      }
    } catch (e) {
      console.warn("Unable to update active link", e);
    }
  }

  private updateState() {
    this.updatePositioning();
    this.updateActiveLink();
  }

  private globalClickHandler(event) {
    const element = <HTMLElement>event.target;
    const isRightCta = element
      .closest("button")
      ?.classList.contains(Classnames.retailerCta);
    if (isRightCta) {
      this.retailerLoading.classList.add(Classnames.flexLoader);
      this.retailerListContainer.classList.add(Classnames.displayNone);
      this.retailerOverlay.classList.add(Classnames.displayNone);
      document.body.classList.add(Classnames.noScroll);
      if (this.retailerLoading.classList.contains(Classnames.flexLoader)) {
        this.removeLoader(event);
      }
    }
  }

  private registerEventHandlers() {
    // Method to handle clicks on the Switch Retailer CTA
    window.addEventListener("click", this.globalClickHandler.bind(this));

    // No need to add event listener if anchors are not present
    if (this.anchors.length) {
      window.addEventListener(
        "scroll",
        throttle(
          function () {
            this.updateState();
          }.bind(this),
          100
        )
      );
    }

    this.anchors.forEach((anchor) => {
      if (anchor.link && anchor.element) {
        anchor.link.addEventListener("click", () => {
          window.scrollTo({
            top: anchor.element.offsetTop - this.container.offsetHeight,
            behavior: "smooth",
          });
        });
      }
    });
  }

  // Method to Display the retailers in the Global Retailer Dropdown
  private displayRetailer(): void {
    const retailerArray = Array.from(this.retailerList.children);
    retailerArray.forEach((retailer) => {
      const data = retailer as HTMLElement;
      const retailerName = data.dataset.retailerTitle;
      if (retailerName === this.retailerTitle.textContent) {
        retailer.classList.add("display-none");
      } else if (retailerName !== this.retailerTitle.textContent) {
        retailer.classList.remove("display-none");
      }
    });
  }

  // Method to close Global Retailer Method when user clicks outside of its content box
  private closeRetailerMenu(): void {
    this.container.addEventListener(
      "click",
      function _listener(e) {
        const tgt = <HTMLElement>e.target;
        const closest = tgt.closest("div");

        const listContainer = document.querySelector(
          `[${Selector.dropdown}="list-container"]`
        );
        const overLay = document.querySelector(
          `[${Selector.dropdown}="overlay"]`
        );

        if (closest !== listContainer) {
          listContainer.classList.add(Classnames.displayNone);
          overLay.classList.add(Classnames.displayNone);
          document.body.classList.remove(Classnames.noScroll);
        }
      },
      true
    );
  }

  // Method to load selected retailer and update retailer dropdown.
  private removeLoader(e) {
    const tgt = <HTMLElement>e.target;
    const closest = tgt.closest("li");
    // Set Time out to imitate loading of content switch
    setTimeout(() => {
      this.retailerTitle.textContent = closest.dataset.retailerTitle;
      this.retailerLoading?.classList.remove(Classnames.flexLoader);
      document.body?.classList.remove(Classnames.noScroll);
    }, 3000);
  }

  private hideButton() {
    const storedCookie = Cookie.get(this.cookieCategory);
    const sampleButtons = document.querySelectorAll(".buy-paint-sample-btn");
    if (storedCookie?.includes("independent-retailer")) {
      sampleButtons.forEach((button) => button.classList.add("hide-button"));
    } else {
      sampleButtons.forEach((button) => button.classList.remove("hide-button"));
    }
  }
}

export { PageNavigator };
