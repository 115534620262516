import { Cookie } from "../../site/scripts/utils";

class CookieAware {
  cookieLabel: string;
  cookieValueToTest: string;
  showFragmentContainer: boolean;
  fragmentContainer: HTMLElement;
  container: HTMLElement;

  constructor(component: HTMLElement) {
    // Elements
    this.container = component;
    this.showFragmentContainer = false;
    this.cookieLabel = this.container.dataset.cmpCookieLabel;
    this.cookieValueToTest = this.container.dataset.cmpCookieValue;
    this.fragmentContainer = this.container.querySelector(
      ".cookie-aware-fragment-container"
    );

    if (!this.container) {
      return null;
    }

    this.retrieveCookie();
    this.showOrHideContentContainer();
  }

  retrieveCookie() {
    const cookie = Cookie.get(this.cookieLabel);
    console.log(this.container.dataset);
    console.log("Cookie:", cookie);

    if (cookie == undefined) {
      this.showFragmentContainer = true;
      console.log("cookie is undefined " + cookie);
    } else {
      if (cookie === this.cookieValueToTest) {
        this.showFragmentContainer = true;
        console.log("Should be showing");
      } else {
        const mode = Cookie.get("wcmmode");
        if (mode === "edit") {
          console.log("in edit mode");
          this.showFragmentContainer = true;
        } else {
          console.log("not in edit mode");
          this.showFragmentContainer = false;
        }
      }
    }
  }

  showOrHideContentContainer() {
    if (this.showFragmentContainer) {
      this.fragmentContainer.hidden = false;
    } else {
      this.fragmentContainer.hidden = true;
    }
  }
}

export { CookieAware };
