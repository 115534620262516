import { Cookie, Session } from "../../site/scripts/utils";

import { PreferredLocation } from "./PreferredLocation";

enum Selector {
  hook = "data-location-searchbar",
}

class CombinedInput {
  // Elements
  container: HTMLElement;
  form: HTMLFormElement;
  input: HTMLInputElement;
  inputType: string;
  errorDiv: HTMLDivElement;
  clearErrorButton: HTMLButtonElement;
  locationResultsDiv: HTMLDivElement;
  preferredLocation: PreferredLocation;
  inputListenerActive: boolean;
  resetErrorCounter = 0;
  cookieId: string;
  noEmailCookieId: string;
  locationErrorMessage: HTMLElement;
  marketTo: HTMLFormElement;
  validationMessage: string;

  private button: HTMLButtonElement;
  private preferredLocationDiv: HTMLDivElement;
  private selectedLocation: HTMLDivElement;

  constructor(el: HTMLElement) {
    this.container = el;
    this.form = this.container.querySelector("form");
    this.errorDiv = this.container.querySelector("[class='error-message']");
    this.input = this.container.querySelector("input");
    this.clearErrorButton = this.container.querySelector("button.clear-input");
    this.inputType = this.input.dataset.inputType;
    this.validationMessage = el.dataset.validationMessage;
    this.marketTo = this.container.closest("form");

    this.inputListenerActive = false;
    this.cookieId = this.container.dataset.fieldCookieId;
    this.preferredLocationDiv = this.container.querySelector(
      ".preferred-location-container"
    );

    if (this.preferredLocationDiv) {
      this.locationResultsDiv = this.container.querySelector(".results-group");
      this.button = this.container.querySelector("button");
      this.preferredLocation = new PreferredLocation(
        this.preferredLocationDiv.dataset["action"],
        this.preferredLocationDiv.dataset["method"],
        this.preferredLocationDiv.dataset["apikey"],
        this.preferredLocationDiv.dataset["errorMessage"],
        this.preferredLocationDiv.dataset["showmoretext"],
        <HTMLElement>el
      );
    }

    this.registerEventHandlers();
  }

  registerEventHandlers() {
    if (this.form) {
      this.form.addEventListener("submit", this.submitHandler.bind(this));
      this.clearErrorButton.addEventListener(
        "click",
        this.clearInputHandler.bind(this)
      );

      this.input.addEventListener("input", this.showHideClearButton.bind(this));
      this.input.addEventListener("focusout", this.validateInput.bind(this));
    }

    if (this.marketTo?.dataset.isMarketo) {
      this.marketTo.addEventListener(
        "submit",
        this.marketToValidation.bind(this)
      );
      this.input.addEventListener("keyup", this.marketToValidation.bind(this));
      this.input.addEventListener(
        "focusout",
        this.marketToValidation.bind(this)
      );
    }

    if (this.preferredLocationDiv) {
      this.locationResultsDiv = this.container.querySelector(".results-group");

      this.button = this.container.querySelector(`[${Selector.hook}="search"]`);
      this.button.addEventListener(
        "click",
        (() => {
          this.fetchLocationHandler();
          this.marketToValidation();
        }).bind(this)
      );
    }
  }

  showHideClearButton() {
    if (
      this.input.value.length &&
      !this.input.classList.contains("show-input")
    ) {
      this.clearErrorButton.setAttribute("tabindex", "0");
      this.input.classList.add("show-input");
    }
    if (!this.input.value.length) {
      this.input.classList.remove("show-input");
      this.clearErrorButton.setAttribute("tabindex", "-1");
    }
  }

  validateInput() {
    const inputValue = this.input.value;
    let validationResult = false;
    if (this.inputType == "email") {
      validationResult = this.emailIsValid(inputValue);
      if (validationResult || inputValue.trim().length == 0) {
        this.errorDiv.classList.remove("invalid");
        this.input.classList.remove("invalid-input");
        this.errorDiv.innerText = "";
      } else {
        this.errorDiv.classList.add("invalid");
        this.input.classList.add("invalid-input");
        this.errorDiv.innerText = this.validationMessage;
      }
    }
  }

  /**
   * @func
   * @desc Handle Clear Input Button on Click and clear related error classes from respective combined Input Component
   * @param event Object
   */
  clearInputHandler(event: Event) {
    if (!this.input.classList.contains("show-input")) {
      return;
    }

    event.preventDefault();

    this.errorDiv.classList.remove("invalid");
    this.input.classList.remove("invalid-input");
    this.input.value = "";
    this.errorDiv.innerText = "";

    this.showHideClearButton();
    this.input.focus();
  }

  async fetchLocationHandler() {
    if (!this.inputIsValid()) {
      return;
    }

    try {
      await this.preferredLocation.submit(this.input.value);
      this.preferredLocation.renderResults(this.locationResultsDiv);
    } catch (e) {
      console.error(`Error fetching preferred locations ${e}`);
    }

    const locationDivs = this.preferredLocationDiv.querySelectorAll(
      ".preferred-location"
    );

    locationDivs.forEach((locationDiv) => {
      locationDiv.addEventListener("click", this.locationSelected.bind(this));
      locationDiv.addEventListener("keyup", this.locationSelected.bind(this));
    });
  }

  locationSelected(event: KeyboardEvent | PointerEvent) {
    if ("key" in event && event.key && event.key != "Enter") {
      return;
    }

    const element = event.target as HTMLElement;
    const preferredLocation = element.closest(".preferred-location");
    const radioInput = preferredLocation.querySelector(
      "input"
    ) as HTMLInputElement;

    if (this.selectedLocation) {
      this.selectedLocation.classList.remove("selected");
    }

    this.selectedLocation = preferredLocation as HTMLDivElement;
    preferredLocation.classList.add("selected");
    radioInput.checked = true;
  }

  inputIsValid() {
    this.resetErrorCounter = 0;
    const inputValue = this.input.value;
    let validationResult = false;

    if (this.inputType == "zip") {
      validationResult = this.zipIsValid(inputValue);
    }

    if (this.inputType == "email") {
      validationResult = this.emailIsValid(inputValue);
    }

    if (this.inputType == "text") {
      validationResult = this.textIsValid(inputValue);
    }

    if (!validationResult) {
      this.errorDiv.classList.add("invalid");
      this.input.classList.add("invalid-input");
      this.errorDiv.innerText = this.validationMessage;

      return false;
    }

    return true;
  }

  submitHandler(event: Event) {
    event.preventDefault();

    if (!this.inputIsValid()) {
      return;
    }

    if (this.cookieId) {
      Cookie.set(this.cookieId, this.input.value, 3000);
      Session.set(this.cookieId, this.input.value);
    }

    // When the combined input type is an email and not a POST, simply redirect to the action url.
    if (
      this.inputType === "email" &&
      this.form.method.toUpperCase() !== "POST"
    ) {
      window.gtm4.populateEmailSignupData(this.form);
      if (this.form.action.trim()) {
        location.href = this.form.action;
      }
    } else {
      this.form.submit();
    }
  }

  removeErrorMessage() {
    //TO REMOVE ERROR  MESSAGES
    this.errorDiv.classList.remove("invalid");
    this.input.classList.remove("invalid-input");
    this.errorDiv.innerText = "";
  }

  marketToValidation() {
    const locationDivs = this.preferredLocationDiv.querySelectorAll(
      ".preferred-location"
    );

    if (locationDivs.length == 0) {
      const inputValue = this.input.value;
      let validationResult = false;
      if (this.inputType == "text") {
        validationResult = this.textIsValid(inputValue);
      }
      if (!validationResult) {
        this.errorDiv.classList.add("invalid");
        this.input.classList.add("invalid-input");
        this.errorDiv.innerText = this.validationMessage;
      } else {
        this.removeErrorMessage();
      }
    } else {
      this.removeErrorMessage();
    }
  }

  zipIsValid(zip: string): boolean {
    const matchResult = zip.match(
      /^(?!00000)(?<zip>(?<zip5>\d{5})(?:[ -](?=\d))?(?<zip4>\d{4})?)$/
    );
    return (
      matchResult !== null && matchResult.length > 0 && zip == matchResult[0]
    );
  }

  emailIsValid(email: string): boolean {
    const matchResult = email.match(
      /^\w+([.-]?\w+)*[^._-]@\w+([.-]?\w+)*(\.\w{2,5})+$/
    );
    return (
      matchResult !== null && matchResult.length > 0 && email == matchResult[0]
    );
  }

  textIsValid(text: string): boolean {
    text = text.trim();
    return text && text.length > 0;
  }
}

export { CombinedInput };
