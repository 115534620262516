import { FavoritesAPI, FavoriteColorItem } from "./FavoritesController";
import { Cookie, Utils } from "../../site/scripts/utils";
import { Message } from "../../site/scripts/Message";
import { State } from "../../enum/state";
import { AskVal, CartItem } from "../cart/AskVal";

enum Classnames {
  displayNone = "display-none",
  iconAdd = "icon-add",
  iconRemove = "icon-remove",
  loading = "loading",
  stateAdd = "cart-cta--add-state",
  stateRemove = "cart-cta--add-remove",
}

enum Selector {
  element = "data-component-id",
  hook = "data-cbg-cmp-hook-cart-cta",
}

class FavoriteCTA {
  // Elements
  container: HTMLElement;
  chipTextRemove: HTMLElement;
  chipTextAdd: HTMLElement;
  persistenceKey = "favorites";
  // Element data attribute identifiers
  brand: string;
  channelId: string;
  collection: string;
  collectionData: string;
  collectionName: string;
  colorId: string;
  colorName: string;
  colorNumber: string;
  colorFamily: string;
  colorsInCollectionText: string;
  country: string;
  hex: string;
  href: string;
  language: string;
  tags: string;

  cartResponseCookie: {
    cart: CartItem[];
    cartItems: CartItem[];
    favorites: CartItem[];
  };
  colorItem: FavoriteColorItem;
  id: number;
  state = State.INACTIVE;
  isDisabled: boolean;
  mdmId: string;
  swatchUrl: string;
  currentBrand: string;
  favoritesMdmVersion: boolean;

  /**
   * Initializes the component.
   * @param component
   * @param favorite
   */
  constructor(component: HTMLElement, favorite?: FavoriteColorItem) {
    this.container = component;

    if (!this.container) return null;

    const data = this.container.dataset;
    this.currentBrand = Utils.getCbgBrand();
    this.brand = data.brand;
    this.channelId = data.favChannelId;
    this.collection = data.collection;
    this.collectionData = data.collectionData;
    this.collectionName = data.collectionName;
    this.colorId = data.favColorId;
    this.colorName = data.favColorName;
    this.colorNumber = data.colorNumber || data.favColorId;
    this.colorFamily = data.colorFamily;
    this.colorsInCollectionText = data.colorsInCollectionText;
    this.country = data.country;
    this.hex = data.favHex;
    this.href = data.href;
    this.language = data.language;
    this.tags = data.favTags;
    this.mdmId = data?.mdmid;
    this.swatchUrl = data?.swatchUrl;
    if (this.collection) {
      this.colorId = this.collection;
      this.colorName = this.collection;
      this.colorNumber = this.collection;
    }

    const element = document.querySelector(
      '[data-cbg-cmp="favorites"]'
    ) as HTMLElement;
    this.favoritesMdmVersion = element?.dataset?.favoritesMdmVersion === "true";
    // Color Item object to send for removing, adding and publishing a favorite
    this.colorItem = {
      cartId: null,
      channelId:
        this.channelId || AskVal.getChannelId(this.brand, this.country),
      collection: this.collection,
      collectionData: this.collectionData,
      collectionName: this.collectionName,
      colorId: this.colorId,
      colorName: this.colorName,
      colorNumber: this.colorNumber,
      colorFamily: this.colorFamily,
      colorsInCollectionText: this.colorsInCollectionText,
      hex: this.hex,
      href: this.href,
      id: this.id,
      localeId: AskVal.getLocaleId(this.language, this.country),
      tags: this.tags,
      timestamp: new Date().getTime(),
      mdmId: this.mdmId,
      swatchUrl: this.swatchUrl,
    };

    const isFavoritesPage = window.location.href.includes("favorites");

    if (favorite || isFavoritesPage) {
      this.updateComponentState(State.ADD);
    }

    this.registerEventHandlers();
    this.registerMessageSubscribers();
    this.initializeUI();
  }

  /**
   * Updates the component's UI based on the state.
   * @private
   * @return void
   */
  private updateUI(): void {
    if (this.state === State.ACTIVE) {
      this.container.classList.add(State.ACTIVE);
      this.chipTextAdd?.classList.add(Classnames.displayNone);
      this.chipTextRemove?.classList.remove(Classnames.displayNone);
    } else {
      this.container.classList.remove(State.ACTIVE);
      this.chipTextAdd?.classList.remove(Classnames.displayNone);
      this.chipTextRemove?.classList.add(Classnames.displayNone);
    }
  }

  /**
   * Updates the component's state property and visual state by toggling the active class.
   * @private
   * @return void
   */
  private updateComponentState(action = "none"): void {
    this.container?.classList.remove(State.LOADING);
    this.state = action === State.ADD ? State.ACTIVE : State.INACTIVE;

    this.updateUI();
  }

  /**
   * Handles state and UI updates when adding a favorite and call the FavoritesAPI to add the favorite.
   * @private
   * @return void
   */
  private addFavorite(): void {
    // Update CTA UI, even before the response comes back.
    if (this.isDisabled) return;
    //Minwax
    if (this.currentBrand === "minwax") {
      const favItems = JSON.parse(
        window.localStorage.getItem(this.persistenceKey) || "[]"
      );
      const favCount = favItems?.length;
      if (favCount >= 8) {
        Utils.msg.publish(Message.addFavoritesErrorResponse, {
          colorName: "You’ve reached the maximum number of favorites allowed",
        });
        return;
      }
    }

    this.updateUI();

    this.container?.classList.add(State.LOADING);
    FavoritesAPI.add(this.colorItem);
    this.container?.classList.remove(State.LOADING);

    this.state = State.ACTIVE;
  }

  /**
   * Handles state and UI updates when removing a favorite and call the FavoritesAPI to remove the favorite.
   * @private
   * @return void
   */
  private removeFavorite(): void {
    // Update CTA state and UI, even before the response comes back.
    this.state = State.INACTIVE;
    this.updateUI();

    this.container?.classList.add(State.LOADING);
    const colorItemID = this.favoritesMdmVersion
      ? this.colorItem.mdmId
      : this.colorItem.colorId;
    FavoritesAPI.remove(colorItemID);
    this.container?.classList.remove(State.LOADING);
  }

  /**
   * Handles clicks on the CTA itself and either adds or removes a favorite accordingly.
   * @param event
   * @return void
   */
  public handleClickBehaviors(event: MouseEvent): void {
    if (this.state === State.INACTIVE) {
      event.preventDefault();
      event.stopPropagation();
      this.addFavorite();
    } else if (this.state === State.ACTIVE) {
      event.preventDefault();
      event.stopPropagation();
      this.removeFavorite();
    }
  }

  /**
   * Registers a click handler and invokes handleClickBehaviors on click events.
   * @private
   * @return void
   */
  private registerEventHandlers(): void {
    this.container.addEventListener(
      "click",
      this.handleClickBehaviors.bind(this)
    );
  }

  /**
   * Registers the message subscribers to handle the adding and removing of a favorite.
   * @private
   * @return void
   */
  private registerMessageSubscribers(): void {
    //Check if minwax then MDM
    const isMdmVersion = this.favoritesMdmVersion;
    Utils.msg.subscribe(Message.addToFavorites, (item) => {
      if (
        (isMdmVersion &&
          item.mdmId !== undefined &&
          this.mdmId === item.mdmId) ||
        (!isMdmVersion &&
          ((item.colorNumber !== undefined &&
            this.colorNumber === item.colorNumber) ||
            (item.collection !== undefined &&
              this.collection === item.collection)))
      ) {
        this.updateComponentState(State.ADD);
      }
    });

    Utils.msg.subscribe(Message.removeFromFavorites, (item) => {
      if (
        (isMdmVersion &&
          item.mdmId !== undefined &&
          this.mdmId === item.mdmId) ||
        (!isMdmVersion &&
          ((item.colorNumber !== undefined &&
            this.colorNumber === item.colorNumber) ||
            (item.collection !== undefined &&
              this.collection === item.collection)))
      ) {
        this.updateComponentState(State.REMOVE);
      }
    });

    Utils.msg.subscribe(
      Message.cookieUpdated,
      (cookie: { retailer: string }) => {
        if ("retailer" in cookie) {
          this.channelId = AskVal.getChannelId(this.brand, this.country);
          this.colorItem.channelId = this.channelId;
        }
      }
    );
    Utils.msg.subscribe(Message.notificationsBannerState, (state) => {
      this.isDisabled = state.isOpen;
    });
  }

  private initializeUI(): void {
    this.chipTextAdd = this.container.querySelector(
      `[${Selector.hook}="chip-text-add"]`
    );

    this.chipTextRemove = this.container.querySelector(
      `[${Selector.hook}="chip-text-remove"]`
    );

    if (this.collection) {
      const cookieValue = Cookie.get("cart-api");
      this.cartResponseCookie = {
        cart: [],
        cartItems: [],
        favorites: [],
      };

      if (cookieValue) {
        this.cartResponseCookie = JSON.parse(cookieValue);
      }

      const collectionFavoriteIndex =
        this.cartResponseCookie.favorites.findIndex(
          (element) =>
            element.colorNumber === this.collection ||
            element.collection === this.collection
        );

      if (collectionFavoriteIndex != -1) {
        this.state = State.ACTIVE;
      }

      this.updateUI();
    }
  }

  /**
   * Handles the global click event and then creates an instance of the FavoriteCTA
   *  if the target matches the Favorite CTA classname.
   *
   * @static
   * @return void
   */
  static handleGlobalClickEvent(event: MouseEvent): void {
    const target = <HTMLElement>event.target;

    if (target.dataset.cmpInit) return;

    const isFavoriteCTA = target.classList.contains("cbg-favorite-cta");
    const isFavoriteCollectionCta =
      target.classList.contains("cbg-coty-collection-favorite") ||
      target.parentElement?.classList.contains("cbg-coty-collection-favorite");

    if (isFavoriteCTA || isFavoriteCollectionCta) {
      event.preventDefault();
      const cta = new FavoriteCTA(target);
      cta.handleClickBehaviors(event);
    }
  }
}

export { FavoriteCTA };
