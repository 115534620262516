import { DataLayerEventName, DataLayerObject, GtmTagName } from "./types";

class Gtm4 {
  constructor() {
    this.init();
  }

  private init() {
    this.ready(() => {
      this.handleWhereToBuy();
      this.handleViewCart();
      this.handleBuyOnline();
      this.handleOrderPaintSample();
      this.handleOrderPaintOnline();
      this.handleFileDownload();
      this.handleAddToCart();
      this.handleIndependentRetailer();
      this.handleOrderComplete();
      this.handleSearchSubmit();
      this.handleSuggestedSearch();
      this.handleSearchResults();
      this.handleViewContent();
      this.handleErrorPage();
      this.handleSelectProduct();
      this.handleViewProduct();
      this.handleFormStart();
      this.handlePaintCalculatorPicker();
      this.handleQuizInteraction();
      this.handleQuizNext();
      this.handleQuizPrevious();
      this.handleQuizSubmit();
      this.handleCta();
      this.handleColorCard();
      this.handleFilterInteraction();
      this.handleFindRepresentative();
      this.handleNavigation();
    });
  }

  public populateEmailSignupData(form: HTMLFormElement) {
    const formId =
      form.id ||
      (<HTMLElement>form.closest(".modal .cbg-cmp-modal"))?.dataset
        ?.componentId ||
      (<HTMLElement>form.closest(".combinedinput-home"))?.dataset
        ?.componentId ||
      "form id not authored";
    const data: DataLayerObject = (<unknown>{
      event: "email_signup",
      gtm_tag_name: "GA4 - Event - email_signup",
      hit_timestamp: this.getHitTimestamp(),
      interaction_type: "email signup",
      placement: this.getEmailFormLocation(form),
      click_id: formId,
    }) as DataLayerObject;
    this.pushToDataLayer(data);
  }

  private handleWhereToBuy(): any {
    this.pushLinkData({}, "a[href*='where-to-buy']");
  }

  private handleViewCart(): any {
    this.pushLinkData({}, "a[href*='viewCart']");
  }

  private handleBuyOnline(): any {
    this.pushLinkData(
      {
        interaction_type: "remove_from_cart",
      },
      "a[data-interaction-type='product-buy-online'], .product-buy-online"
    );
  }

  private handleOrderPaintSample(): any {
    this.pushLinkData(
      {
        interaction_type: "color_card",
      },
      "a[data-interaction-type='color-order-sample']"
    );
  }

  private handleOrderPaintOnline(): any {
    this.pushLinkData(
      {
        interaction_type: "color_card",
      },
      "a[data-interaction-type='color-order-online']"
    );
  }

  private handleFileDownload() {
    const links = document.querySelectorAll(
      'a[href*="paintdocs.com"], a[href*=".pdf"]'
    );

    const commonData = {
      event: "file_download",
      document_extension: "PDF",
    };

    links.forEach((link: HTMLAnchorElement) => {
      const documentAbbreviation = link?.dataset?.attrType;
      let documentType = "guide";
      if (documentAbbreviation === "SDS") {
        documentType = "safety data sheet";
      } else if (documentAbbreviation === "PDS") {
        documentType = "product data sheet";
      }
      const documentTitle = document.title;
      link.addEventListener("click", () => {
        this.pushToDataLayer({
          event: "file_download",
          gtm_tag_name: "GA4 - Event - file_download",
          hit_timestamp: this.getHitTimestamp(),
          interaction_type: "file download",
          ...commonData,
          document_type: documentType,
          document_title: documentTitle,
          document_url: link.href,
        } as DataLayerObject);
      });
    });
  }

  private handleAddToCart = () => {
    const cartBtnSelectors = ["cart-cta--button"];

    cartBtnSelectors.forEach((sel) => {
      // Select buttons with class names
      const buttons = document.querySelectorAll(`.${sel}`);

      // Add click listener for each button
      buttons.forEach((button: HTMLElement) => {
        button.onclick = (e) => this.addBtnToDataLayer(e);
      });
    });
  };

  private addBtnToDataLayer = (e) => {
    const target = e.target.closest("button");
    const btnData = this.getDataLayerObject(target, e);
    const pageData = this.getPageViewData();
    const cleanData = this.cleanEmptyValues({ ...pageData, ...btnData });

    this.pushToDataLayer(cleanData as DataLayerObject);
  };

  private handleIndependentRetailer(): any {
    const irSelect: HTMLSelectElement = document.getElementById(
      "global-retailer-selector"
    ) as HTMLSelectElement;
    if (irSelect) {
      irSelect.addEventListener("change", (event) => {
        const irValue =
          irSelect.value === "Independent Retailer" ? "Independent" : "Lowe's";
        const result: object = {
          gtm_tag_name: this.getGtmTagName(irSelect),
          hit_timestamp: this.getHitTimestamp(),
          interaction_type: this.getInteractionType(irSelect),
          retailer_type: irValue,
        };

        this.pushToDataLayer(result as DataLayerObject);
      });
    }
  }

  private handleOrderComplete(): any {
    if (document.URL.indexOf("order-confirmation") > -1) {
      const pageViewData = this.cleanEmptyValues(this.getPageViewData());
      const additionalFields: object = {
        event: "order_complete",
        gtm_tag_name: "GA4 - Event - order_complete",
        interaction_type: "order complete",
        language: "",
        page_category_one: "",
        page_name: "",
        user_type: "",
      };

      const mergedData: object = this.cleanEmptyValues({
        ...pageViewData,
        ...additionalFields,
      }) as DataLayerObject;

      this.pushToDataLayer(mergedData as DataLayerObject);
    }
  }

  private handleSearchSubmit(): void {
    const searchSubmitData: DataLayerObject = {
      event: "search",
      hit_timestamp: this.getHitTimestamp(),
      gtm_tag_name: "GA4 - Event - Search",
      interaction_type: "search",
    };

    const forms = document.querySelectorAll(
      ".cmp-searchbar__form:not([action='/'])"
    );
    forms.forEach((form: HTMLFormElement) => {
      const theForm = form.closest("form");
      theForm.addEventListener(
        "submit",
        (event) => {
          event.preventDefault();
          event.stopPropagation();
          searchSubmitData.search_term = (<HTMLInputElement>(
            theForm.querySelector(".cmp-searchbar__input")
          )).value;
          this.pushToDataLayer(searchSubmitData as DataLayerObject);
          form.submit();
        },
        true
      );
    });
  }

  private handleSuggestedSearch(): void {
    const suggestedSearchData: DataLayerObject = {
      event: "search",
      hit_timestamp: this.getHitTimestamp(),
      gtm_tag_name: "GA4 - Event - Search",
      interaction_type: "search",
    };

    const suggestedResultsContainers = document.querySelectorAll(
      ".cmp-searchbar__results"
    );

    suggestedResultsContainers.forEach((container: HTMLElement) => {
      container.addEventListener("click", (event) => {
        event.preventDefault();
        const eventTarget: HTMLElement = <HTMLAnchorElement>event.target;
        if (
          eventTarget.classList.contains("cmp-searchbar__autocomplete-item")
        ) {
          suggestedSearchData.search_term = eventTarget.innerText;
          this.pushToDataLayer(suggestedSearchData);
          const urlTarget = eventTarget.getAttribute("target");
          const url = (<HTMLAnchorElement>eventTarget).href;
          if (this.openInNewTab(<HTMLAnchorElement>eventTarget)) {
            window.open(url);
          } else {
            document.location = url;
          }
        }
      });
    });
  }

  private handleSearchResults() {
    let searchTerm = "N/A";
    const viewSearchResultsData: DataLayerObject = {
      event: "view_search_results",
      hit_timestamp: this.getHitTimestamp(),
      gtm_tag_name: "GA4 - Event - view_search_results",
      interaction_type: "view_search_results",
    };
    window.addEventListener(
      "searchRendered",
      (searchRenderedEvent: CustomEvent) => {
        searchTerm = searchRenderedEvent.detail?.results?.query || "N/A";
        const container = searchRenderedEvent.detail?.container;
        const links = container?.querySelectorAll("a");
        links?.forEach((link) => {
          link?.addEventListener("click", (linkEvent) => {
            const searchType =
              link.closest(".cbg-cmp-search-results__content-container")
                ?.dataset?.resultsContainerType || "N/A";
            viewSearchResultsData.search_type = searchType;
            viewSearchResultsData.search_term = searchTerm;
            viewSearchResultsData.search_url = link?.href || "N/A";
            this.pushToDataLayer(viewSearchResultsData);
          });
        });
      }
    );
  }

  // Push view_content event to dataLayer on page load when URL does not contain the specified paths (e.g., '/products')
  private handleViewContent() {
    const viewContentData: DataLayerObject = {
      event: "view_content",
      gtm_tag_name: "GA4 - Event - select_content",
      hit_timestamp: this.getHitTimestamp(),
      interaction_type: "content",
    };
    if (
      /^((?!\/products|\/colors|\/paints|\/about-us|\/contact-us|\/press-room)\/?.)*$/.test(
        document.URL
      )
    ) {
      viewContentData.page_title = document.title;
      viewContentData.page_url = document.URL;
      this.pushToDataLayer(viewContentData);
    }
  }

  private handleErrorPage() {
    const errorPageData: DataLayerObject = {
      event: "error",
      gtm_tag_name: "GA4 - Event - error",
      hit_timestamp: this.getHitTimestamp(),
      interaction_type: "error",
    };
    if (/\b404\b/.test(document.title)) {
      errorPageData.error_code = "404";
      errorPageData.error_message = "Looks like this page is missing or moved.";
      this.pushToDataLayer(errorPageData);
    }
  }

  private handleViewProduct() {
    if (document.querySelectorAll(".product-detail-home").length > 0) {
      const viewProductData: DataLayerObject = {
        event: "view_product",
        gtm_tag_name: "GA4 - Event - view_product",
        hit_timestamp: this.getHitTimestamp(),
        interaction_type: "product view",
      };
      const pageViewData = this.cleanEmptyValues(this.getPageViewData());
      this.pushToDataLayer({
        ...pageViewData,
        ...viewProductData,
      });
    }
  }

  private handleSelectProduct() {
    const selectProductLinks = document.querySelectorAll(
      ".product-image-link, .product-name-link, .product-view-details-link"
    );
    selectProductLinks.forEach((link: HTMLAnchorElement) => {
      link.addEventListener("click", (event) => {
        const pageViewData = this.getPageViewData();
        const clickPosition =
          Number.parseInt(
            (<HTMLAnchorElement>link.closest(".grid--wall__item")).dataset
              ?.index
          ) + 1;
        const selectProductData: DataLayerObject = {
          event: "select_product",
          gtm_tag_name: "GA4 - Event - select_product",
          hit_timestamp: this.getHitTimestamp(),
          interaction_type: "select product",
          product_name: link.dataset?.productName,
          product_id: link.dataset?.productId,
          product_category: this.getProductCategoryFromUrl(),
          click_position: clickPosition.toString() || "N/A",
        };
        const combinedData = {
          ...pageViewData,
          ...selectProductData,
        };
        this.pushToDataLayer(
          <DataLayerObject>this.cleanEmptyValues(combinedData)
        );
      });
    });
  }

  private handleFormStart() {
    if (document.querySelectorAll('[data-form-type="contactUs"]').length > 0) {
      const formStartData: DataLayerObject = {
        event: "form_start",
        gtm_tag_name: "GA4 - Event - form_start",
        hit_timestamp: this.getHitTimestamp(),
        interaction_type: "interaction",
        outbound: "internal",
        form_type: "Contact Us",
      };
      this.pushToDataLayer(formStartData);
    }
  }

  private handlePaintCalculatorPicker() {
    const pickerButtons = document.querySelectorAll(
      ".paint-calculator .measurement-picker button.calc-picker"
    );
    pickerButtons.forEach((button) => {
      button.addEventListener("click", (event) => {
        event.preventDefault();
        const obj = {
          event: "paint_calculator",
          gtm_tag_name: "GA4 - Event - paint_calculator",
          hit_timestamp: this.getHitTimestamp(),
          interaction_type: "button",
          outbound: "internal",
          tab: (<HTMLElement>event.target).closest("button").innerText,
        };
        this.pushToDataLayer(obj as DataLayerObject);
      });
    });
  }

  private handleQuizInteraction() {
    const quizData = {
      event: "quiz_interaction",
      gtm_tag_name: "GA4 - Event - quiz_interaction",
      hit_timestamp: this.getHitTimestamp(),
      interaction_type: "interaction",
      quiz_name: document.title,
    };

    document.addEventListener("quizAnswerClicked", function (ev: CustomEvent) {
      console.log("ev.detail: ", ev.detail);
      console.log("this: %o", this);
      window.dataLayer.push({ ...quizData, ...ev.detail });
    });
  }

  private handleQuizNext() {
    const quizData = {
      event: "quiz_next",
      gtm_tag_name: "GA4 - Event - quiz_next",
      hit_timestamp: this.getHitTimestamp(),
      interaction_type: "button",
      outbound: "internal",
    };

    document.addEventListener("quizNextClicked", function (ev: CustomEvent) {
      window.dataLayer.push({ ...quizData, ...ev.detail });
    });
  }

  private handleQuizPrevious() {
    const quizData = {
      event: "quiz_previous",
      gtm_tag_name: "GA4 - Event - quiz_previous",
      hit_timestamp: this.getHitTimestamp(),
      interaction_type: "button",
      outbound: "internal",
    };

    document.addEventListener(
      "quizPreviousClicked",
      function (ev: CustomEvent) {
        window.dataLayer.push({ ...quizData, ...ev.detail });
      }
    );
  }

  private handleQuizSubmit() {
    const quizData = {
      event: "quiz_submit",
      gtm_tag_name: "GA4 - Event - quiz_submit",
      hit_timestamp: this.getHitTimestamp(),
      interaction_type: "button",
      outbound: "internal",
    };

    document.addEventListener("quizSubmitted", function (ev: CustomEvent) {
      window.dataLayer.push({ ...quizData, ...ev.detail });
    });
  }

  private handleCta() {
    const eventData: DataLayerObject = {
      event: "cta",
      gtm_tag_name: "GA4 - Event - cta",
      hit_timestamp: this.getHitTimestamp(),
      interaction_type: "cta",
    };

    const ctas = document.querySelectorAll(".button-link");
    ctas.forEach((cta: HTMLAnchorElement) => {
      cta.addEventListener("click", (event) => {
        eventData.click_text = cta?.innerText || "";
        eventData.click_url = cta?.href || "";
        this.pushToDataLayer(eventData);
      });
    });
  }

  private handleColorCard() {
    const eventData: DataLayerObject = {
      event: "color_card",
      gtm_tag_name: "GA4 - Event - color_card",
      hit_timestamp: this.getHitTimestamp(),
      interaction_type: "color_card",
    };
    const colorCards = document.querySelectorAll(".grid--wall__item-color");
    colorCards.forEach((colorCard: HTMLElement) => {
      colorCard.addEventListener("click", (event) => {
        // color_number is the customer-facing color number, but it's in the data attributes as color-id
        eventData.color_number = colorCard?.dataset.colorId || "N/A";
        eventData.color_name = colorCard?.dataset.colorName || "N/A";
        eventData.color_family = colorCard?.dataset.colorFamily || "N/A";
        eventData.color_collection =
          colorCard?.dataset.colorCollection || "N/A";

        // color_id is an internal id, and is in the swatch container's data attributes as color-id
        const swatchContainer: HTMLElement = colorCard.querySelector(
          ".cbg-cmp-wall-item__swatch-container"
        );
        eventData.color_id = swatchContainer?.dataset.colorId || "N/A";
        this.pushToDataLayer(eventData);
      });
    });
  }

  private handleFilterInteraction() {
    const eventData: DataLayerObject = {
      event: "filter_interaction",
      gtm_tag_name: "GA4 - Event - filter_interaction",
      hit_timestamp: this.getHitTimestamp(),
      interaction_type: "interaction",
    };

    const filterToggles = document.querySelectorAll(
      ".filter-checkbox__input, .filter-color"
    );
    filterToggles.forEach((filterToggle: HTMLInputElement) => {
      filterToggle.addEventListener("change", (event) => {
        eventData.filter_type = filterToggle?.dataset.groupId || "";
        eventData.filter_selection = filterToggle?.name || "";
        this.pushToDataLayer(eventData);
      });
    });
  }

  private handleFindRepresentative() {
    const eventData: DataLayerObject = {
      event: "find_representative",
      gtm_tag_name: "GA4 - Event - find_representative",
      hit_timestamp: this.getHitTimestamp(),
      interaction_type: "button",
      outbound: "internal",
    };

    const findRepButton = document.querySelector(
      "#find-rep-button .button-link"
    );
    if (findRepButton) {
      findRepButton.addEventListener("click", (event) => {
        this.pushToDataLayer(eventData);
      });
    }
  }

  private handleNavigation() {
    const eventData: DataLayerObject = {
      event: "navigation",
      gtm_tag_name: "GA4 - Event - navigation",
      hit_timestamp: this.getHitTimestamp(),
      interaction_type: "navigation",
    };
    const navLinks = document.querySelectorAll(
      "[data-cbg-cmp^='site-navigation'] a, [class^='cmp-breadcrumb'] a,  .footer a"
    );
    navLinks?.forEach((navLink: HTMLAnchorElement) => {
      navLink?.addEventListener("click", (event) => {
        const eventTarget: HTMLAnchorElement = (<HTMLElement>(
          event.target
        )).closest("a");
        let placement = "main";
        if (eventTarget.classList.contains("cmp-breadcrumb__item-link")) {
          placement = "breadcrumbs";
        } else if (eventTarget.closest(".footer")) {
          placement = "footer";
        }
        eventData.link_text = eventTarget.innerText;
        eventData.link_url = eventTarget.href;
        eventData.placement = placement;
        this.pushToDataLayer(eventData);
      });
    });
  }

  // Push link and pageview data to the dataLayer
  private pushLinkData(
    additionalFields: any = {},
    selector = "a:not([href='#cpra'])"
  ): any {
    const links = document.querySelectorAll(selector);
    links.forEach((link: HTMLAnchorElement) => {
      link.onclick = (event) => {
        event.preventDefault();
        const eventTarget: HTMLAnchorElement = (<HTMLElement>(
          event.target
        )).closest("a");
        const href: string = eventTarget.href || "";
        if (href && href.indexOf("close-modal") < 0) {
          const mainData: object = this.cleanEmptyValues(
            this.getDataLayerObject(eventTarget)
          );

          const pageViewData: object = this.cleanEmptyValues(
            this.getPageViewData()
          );

          const mergedData: DataLayerObject = {
            ...pageViewData,
            ...mainData,
            ...additionalFields,
          } as DataLayerObject;

          this.pushToDataLayer(mergedData);

          if (this.openInNewTab(eventTarget)) {
            window.open(href);
          } else {
            document.location = href;
          }
        }
      };
    });
  }

  private pushFormData(additionalFields: any = {}, selector = "form") {
    const forms = document.querySelectorAll(selector);
    forms.forEach((form: HTMLFormElement) => {
      const theForm = form.closest("form");
      theForm.addEventListener(
        "submit",
        (event) => {
          event.preventDefault();
          event.stopPropagation();
          const eventTarget: HTMLFormElement = (<HTMLElement>(
            event.target
          )).closest("form");
          const action: string = eventTarget.action || "";
          if (action) {
            const pageViewData: object = this.cleanEmptyValues(
              this.getPageViewData()
            );

            const mainData: object = this.cleanEmptyValues(
              this.getDataLayerObject(eventTarget)
            );

            const mergedData: DataLayerObject = {
              ...pageViewData,
              ...mainData,
              ...additionalFields,
            } as DataLayerObject;

            this.pushToDataLayer(mergedData as DataLayerObject);
          }
          form.submit();
        },
        true
      );
    });
  }

  private pushToDataLayer(obj: DataLayerObject) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ...obj });
  }

  private getDataLayerObject(target: HTMLElement, e?: Event): DataLayerObject {
    return <DataLayerObject>(<object>{
      click_id: this.getClickId(target),
      click_position: this.getClickPosition(target, e),
      color_collection: this.getColorCollection(target),
      color_family: this.getColorFamily(target),
      color_id: this.getColorId(target),
      color_name: this.getColorName(target),
      environment: this.getEnvironment(),
      event: this.getGtmEventName(target),
      gtm_tag_name: this.getGtmTagName(target),
      hit_timestamp: this.getHitTimestamp(),
      interaction_type: this.getInteractionType(target),
      link_text: this.getLinkText(<HTMLAnchorElement>target),
      link_url: this.getLinkUrl(<HTMLAnchorElement>target),
      placement: this.getPlacement(<HTMLAnchorElement>target),
      product_category:
        this.getProductCategoryFromDataAttribute(target) ||
        this.getProductCategoryFromUrl(),
      product_id: this.getProductId(target),
      product_name: this.getProductName(target),
      product_retailer: this.getProductRetailer(target),
      retailer_type: this.getRetailerType(target),
      room_family: this.getRoomFamily(target),
      store_id: this.getStoreId(target),
      store_name: this.getStoreName(target),
    });
  }

  private getGtmEventName(target: HTMLElement): DataLayerEventName {
    const targetClasses = target.classList;
    if (
      targetClasses.contains("product-buy-online") ||
      targetClasses.contains("retailer-item-link") ||
      target.dataset.interactionType === "product-buy-online"
    ) {
      return "buy_online";
    } else if ((<HTMLAnchorElement>target).href?.indexOf("where-to-buy") > -1) {
      return "where_to_buy";
    } else if (target.dataset.cbgCmp === "cart-cta") {
      return "add_to_cart";
    } else if ((<HTMLAnchorElement>target).href?.indexOf("viewCart") > -1) {
      return "view_cart";
    } else if (target.dataset.interactionType === "color-order-online") {
      return "order_paint_online";
    } else if (target.dataset.interactionType === "color-order-sample") {
      return "order_paint_sample";
    } else if ((<HTMLFormElement>target).querySelector('input[type="email"]')) {
      return "email_signup";
    } else if (
      target instanceof HTMLAnchorElement &&
      (target.closest(".product-name-link") ||
        target.closest(".product-image-link") ||
        target.closest(".product-view-details-link"))
    ) {
      return "select_product";
    } else if (
      target instanceof HTMLAnchorElement &&
      (target.closest(".cmp-experiencefragment--header") ||
        target.closest(".cmp-experiencefragment--footer") ||
        target.closest(".cmp-breadcrumb"))
    ) {
      return "navigation";
    } else if (target.classList.contains("color-anchor")) {
      return "color_card";
    } else if (
      targetClasses.contains("button") ||
      targetClasses.contains("btn")
    ) {
      return "cta";
    } else if (
      targetClasses.contains("link") ||
      targetClasses.contains("image")
    ) {
      return "interaction";
    }
    return "";
  }

  private getGtmTagName(target: HTMLElement): GtmTagName {
    if (
      target.classList.contains("product-buy-online") ||
      target.classList.contains("retailer-item-link") ||
      target.dataset.interactionType === "product-buy-online"
    ) {
      return "GA4 - Event - buy_online";
    } else if (target.dataset.cbgCmp === "cart-cta") {
      return "GA4 - Event - add_to_cart";
    } else if ((<HTMLAnchorElement>target).href?.indexOf("where-to-buy") > -1) {
      return "GA4 - Event - where_to_buy";
    } else if ((<HTMLAnchorElement>target).href?.indexOf("viewCart") > -1) {
      return "GA4 - Event - view_cart";
    } else if (target.dataset.interactionType === "color-order-online") {
      return "GA4 - Event - order_paint_online";
    } else if (target.dataset.interactionType === "color-order-sample") {
      return "GA4 - Event - order_paint_sample";
    } else if ((<HTMLFormElement>target).querySelector('input[type="email"]')) {
      return "GA4 - Event - email_signup";
    } else if (target.id === "global-retailer-selector") {
      return "GA4 - Event - preferred_retailer";
    } else if (
      target instanceof HTMLAnchorElement &&
      (target.closest(".product-name-link") ||
        target.closest(".product-image-link") ||
        target.closest(".product-view-details-link"))
    ) {
      return "GA4 - Event - select_product";
    } else if (target.classList.contains("color-anchor")) {
      return "GA4 - Event - color_card";
    } else if (
      target instanceof HTMLAnchorElement &&
      (target.closest(".cmp-experiencefragment--header") ||
        target.closest(".cmp-experiencefragment--footer") ||
        target.closest(".cmp-breadcrumb"))
    ) {
      return "GA4 - Event - navigation";
    } else if (
      target.classList.contains("button") ||
      target.classList.contains("btn")
    ) {
      return "GA4 - Event - cta";
    } else if (
      target.classList.contains("link") ||
      target.classList.contains("image")
    ) {
      return "GA4 - Event - interaction";
    }
    return "";
  }

  private getHitTimestamp() {
    try {
      return new Date().toISOString();
    } catch (e) {
      return new Date(0).toISOString(); // If there is an error, return the epoch as a valid datetime string
    }
  }

  private getInteractionType(target: HTMLElement): string {
    if ((<HTMLAnchorElement>target).href?.indexOf("where-to-buy") > -1) {
      return "cta";
    } else if (target.dataset.cbgCmp === "cart-cta") {
      return "add to cart";
    } else if ((<HTMLAnchorElement>target).href?.indexOf("viewCart") > -1) {
      return "view cart";
    } else if (
      target.dataset.interactionType === "product-buy-online" ||
      target.classList.contains("retailer-item-link") ||
      target.classList.contains("product-buy-online")
    ) {
      return "product_buy_online";
    } else if (target.dataset.interactionType === "color-order-online") {
      return "color card";
    } else if (target.dataset.interactionType === "color-order-sample") {
      return "color card";
    } else if (target.classList.contains("cmp-navigation__item-link")) {
      return "navigation";
    } else if ((<HTMLFormElement>target).querySelector('input[type="email"]')) {
      return "email signup";
    } else if (target.id === "global-retailer-selector") {
      return "button";
    } else if (
      target instanceof HTMLAnchorElement &&
      (target.closest(".product-name-link") ||
        target.closest(".product-image-link") ||
        target.closest(".product-view-details-link"))
    ) {
      return "select_product";
    } else if (target.classList.contains("color-anchor")) {
      return "color card";
    } else if (
      target instanceof HTMLAnchorElement &&
      (target.closest(".cmp-experiencefragment--header") ||
        target.closest(".cmp-experiencefragment--footer") ||
        target.closest(".cmp-breadcrumb"))
    ) {
      return "navigation";
    } else if (
      target.classList.contains("button") ||
      target.classList.contains("btn")
    ) {
      return "cta";
    } else if (
      target.classList.contains("link") ||
      target.classList.contains("image")
    ) {
      return "interaction";
    }
    return "";
  }

  private getPlacement(target: HTMLAnchorElement): string {
    if (target.closest(".cmp-experiencefragment--header")) {
      return "header";
    } else if (target.closest(".cmp-experiencefragment--footer")) {
      return "footer";
    } else if (target.closest(".cmp-breadcrumb")) {
      return "breadcrumbs";
    }
    return "";
  }

  private getLinkText(target: HTMLAnchorElement): string {
    if (target.classList.contains("product-buy-online")) {
      return target.querySelector(".button-text").innerHTML;
    }
    return "";
  }

  private getLinkUrl(target: HTMLAnchorElement): string {
    const href = target.getAttribute("href");
    if (href) {
      return href;
    }
    return "";
  }

  private getEnvironment(): string {
    const url: string = document.URL;
    if (url.indexOf("localhost") > -1) {
      return "local";
    } else if (url.indexOf("develop") > -1) {
      return "develop";
    } else if (url.indexOf("qa") > -1) {
      return "qa";
    } else if (url.indexOf("uat") > -1) {
      return "uat";
    }
    return "prod";
  }

  private getProductName(target: HTMLElement): string {
    const productName: string = target.dataset.productName || "";
    return productName;
  }

  private getProductId(target: HTMLElement): string {
    const productId: string = target.dataset.productId || "";
    return productId;
  }

  private getProductCategoryFromDataAttribute(target: HTMLElement): string {
    const productCategory: string = target.dataset.productCategory || "";
    return productCategory;
  }

  private getProductCategoryFromUrl(): string {
    const url = document.URL;
    const suffix = ".html";
    return url.split(suffix)[0].split("/").at(-1);
  }

  private getProductRetailer(target: HTMLElement): string {
    const productRetailer: string = target.dataset.retailer || "";
    return productRetailer;
  }

  private getColorFamily(target: HTMLElement): string {
    const colorFamily: string = target.dataset.colorFamily || "";
    return colorFamily;
  }

  private getPageViewData() {
    const pageViewData = window.dataLayer.find((x) => x.event === "page_view");
    return pageViewData;
  }

  private getEmailFormLocation(formElement: HTMLElement): string {
    let location = "page";
    if (formElement && formElement.closest(".cmp-experiencefragment--footer")) {
      location = "footer";
    } else if (formElement && formElement.closest(".cbg-cmp-modal")) {
      location = "popup";
    }
    return location;
  }

  private getStoreName(target: HTMLElement): string {
    return target.dataset.storeName || "";
  }
  private getStoreId(target: HTMLElement): string {
    return target.dataset.storeId || "";
  }
  private getRoomFamily(target: HTMLElement): string {
    return target.dataset.roomFamily || "";
  }
  private getColorCollection(target: HTMLElement): string {
    return target.dataset.colorCollection || "";
  }
  private getColorId(target: HTMLElement): string {
    return target.dataset.colorId || "";
  }
  private getColorName(target: HTMLElement): string {
    return target.dataset.colorName || "";
  }
  private getClickPosition(target: HTMLElement, e: Event): object {
    const ev = e as MouseEvent;

    return { x: ev?.clientX, y: ev?.clientY };
  }
  private getClickPlacement(target: HTMLElement): string {
    return target.dataset.clickPlacement || "";
  }
  private getClickId(target: HTMLElement): string {
    return target.dataset.clickId || "";
  }
  private getRetailerType(target: HTMLElement): string {
    return target.dataset.retailerType || "";
  }

  private cleanEmptyValues(obj: object): object {
    if (obj) {
      const cleanObject = Object.keys(obj)
        .filter((k) => obj[k] != "")
        .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
      return cleanObject;
    } else {
      return {};
    }
  }

  private openInNewTab(anchor: HTMLAnchorElement): boolean {
    return anchor?.target === "_blank";
  }

  private ready = (fn) => {
    if (document.readyState !== "loading") {
      fn();
    } else {
      document.addEventListener("DOMContentLoaded", fn);
    }
  };
}

export { Gtm4 };
