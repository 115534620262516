import { Utils } from "../../site/scripts/utils";

enum Selectors {
  submissionBtn = ".bv-write-review-label.bv-submission-button",
  starsContainer = ".bv-content-placeholder .bv-rating-stars-container",
  ratingsContainer = ".ratings-reviews",
}

class RatingsReviews {
  // Elements
  component: HTMLElement;
  firstReviewText: string;
  buttonText: string;
  offScreenText: string;

  constructor(component: HTMLElement) {
    this.component = component;
    this.firstReviewText = this.component.dataset.firstReviewText;
    this.buttonText = this.component.dataset.buttonText;
    this.offScreenText = this.component.dataset.offScreenText;

    this.registerEvents();
  }

  private registerEvents() {
    Utils.elementReady(Selectors.submissionBtn).then(() => {
      const starsContainer = this.component.querySelector(
        Selectors.starsContainer
      );
      const submissionBtn = this.component.querySelector(
        Selectors.submissionBtn
      );

      if (submissionBtn) {
        submissionBtn.innerHTML =
          "<div class='ratings-reviews-cta-first-text first'>" +
          this.firstReviewText +
          "</div>" +
          "<div class='ratings-reviews-cta-container'>" +
          "<button class='bv-write-review bv-focusable bv-submission-button ratings-reviews-cta-first-btn' type='button'>" +
          this.buttonText +
          "</button><span class='bv-off-screen'>" +
          this.offScreenText +
          "</span></div>";
      }
      if (starsContainer) {
        starsContainer.innerHTML = "";
      }
    });
  }
}

export { RatingsReviews };
