import { throttle } from "lodash";

export default class Analytics {
  static trackEventElement(e: Event): void {
    const arr = [];
    const analytic = document.getElementsByClassName("banner"); // change to getElementById and event id.
    if (analytic) {
      const clickedElement = window.event
          ? (window.event.target as HTMLElement)
          : (e.target as HTMLElement),
        elProps = document.getElementsByClassName(clickedElement.className); // change to getElementById and event id.

      for (let i = 0; i < elProps.length; ++i) {
        if (elProps[i] == clickedElement) {
          arr.push({ evtAction: clickedElement.className, index: i });
        }
      }
    }
    console.log(arr[0]); // will have event id and event action
  }

  static scrollEventHandler(element: HTMLElement): void {
    const isScrollCounted =
      element.dataset.scrollCounted && element.dataset.scrollCounted === "true";
    if (!isScrollCounted) {
      const elTop = element.offsetTop;
      const elHt = element.offsetHeight;
      const elBot = elTop + elHt;

      const winY = window.scrollY;
      const winHt = window.outerHeight;
      const winBot = winY + winHt;

      const isInView = elBot <= winBot && elTop >= winY;

      if (isInView) {
        element.dataset.scrollCounted = `${isInView}`;

        try {
          console.log("gtm.scroll event sent");
          window.dataLayer.push({
            event: "gtm.scroll",
            campaignId: element.dataset.campaignId || "",
            componentId: element.dataset.componentId || "",
          });
        } catch (e) {
          console.error("Scroll capture error", e);
        }
      }
    }
  }

  static clickEventHandler(evt: Event): void {
    const element = <HTMLElement>evt.target;
    try {
      console.log("gtm.click event sent");
      window.dataLayer.push({
        event: "gtm.click",
        campaignId: element.dataset.campaignId || "",
        componentId: element.dataset.componentId || "",
      });
    } catch (e) {
      console.error("Click capture error", e);
    }
  }

  static registerAnalyticsComponents(): void {
    const trackableComponents = Array.from(
      document.querySelectorAll("[data-analytics-json]")
    );
    trackableComponents.forEach(function (item) {
      const element = <HTMLElement>item;
      const rawItemAnalyticsEvents =
        element.dataset && element.dataset.analyticsJson;
      const itemAnalyticsEvents = JSON.parse(rawItemAnalyticsEvents);

      itemAnalyticsEvents.forEach((eventObj) => {
        const { analyticsaction } = eventObj;
        if (analyticsaction == "scroll-action") {
          window.addEventListener(
            "scroll",
            throttle(function () {
              Analytics.scrollEventHandler(element);
            }, 250)
          );
        } else if (analyticsaction == "click-action") {
          element.addEventListener("click", Analytics.clickEventHandler);
        }
      });
    });
  }
}
