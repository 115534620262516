const hiddenClass = "hidden";

enum Selector {
  hook = "data-cmp-hook-product-lookup",
}

class ProductLookup {
  component: HTMLElement;
  input: HTMLInputElement;
  products: NodeListOf<HTMLLIElement>;
  noResultsNode: HTMLLIElement;
  productData: HTMLSpanElement;
  listContainer: HTMLUListElement;

  constructor(component: HTMLElement) {
    this.component = component;

    if (!this.component) {
      return;
    }

    this.input = this.component.querySelector(`[${Selector.hook}="input"]`);
    this.products = this.component.querySelectorAll(
      `[${Selector.hook}="product-list-item"]`
    );
    this.listContainer = this.component.querySelector(
      `[${Selector.hook}="product-list"]`
    );

    this.noResultsNode = this.component.querySelector(
      `[${Selector.hook}="noResultsText"]`
    );
    this.registerEventListeners();
  }

  registerEventListeners(): void {
    this.input.addEventListener(
      "input",
      this.filterProductsFromInput.bind(this)
    );
    this.input.addEventListener("focusout", this.hideProductList.bind(this));
    this.input.addEventListener("focusin", this.showProductList.bind(this));

    const productAnchorTags = this.component.querySelectorAll("a");
    productAnchorTags.forEach((el) => {
      el.addEventListener("mousedown", this.handleProductSelect.bind(this));
    });
  }

  hideProductList() {
    this.listContainer.classList.add(hiddenClass);
  }

  showProductList() {
    this.listContainer.classList.remove(hiddenClass);
  }

  filterProductsFromInput(event: InputEvent) {
    const input = event.target as HTMLInputElement;
    let productsHidden = this.products.length;

    this.products.forEach((el) => {
      if (el.innerText.toLowerCase().indexOf(input.value.toLowerCase()) > -1) {
        el.classList.remove(hiddenClass);
        productsHidden--;
      } else {
        el.classList.add(hiddenClass);
      }
    });

    if (this.products.length == productsHidden) {
      this.noResultsNode.classList.remove(hiddenClass);
    } else {
      this.noResultsNode.classList.add(hiddenClass);
    }
  }

  handleProductSelect(event: PointerEvent) {
    console.debug("Product selected");
    const productAnchorTag = event.target as HTMLAnchorElement;
    const { productName, productId } = productAnchorTag.dataset;
    this.input.value = "One moment for details on " + productName;
    this.hideProductList();
    console.debug("selected a product.  come back here");
    const titleElement = <HTMLElement>document.getElementById("specs-guy");
    titleElement.innerHTML = "";

    const server = window.location.origin;
    let serverlessPath = window.location.pathname;
    const periodPosition = serverlessPath.indexOf(".");
    const hasHtmlOrProductPostfix = periodPosition > -1;

    if (hasHtmlOrProductPostfix) {
      serverlessPath = serverlessPath.substring(0, periodPosition);
    }

    const newPath = `${serverlessPath}.${productId}.html`;
    window.location.href = server + newPath;
  }
}

export { ProductLookup };
