import { Cookie } from "../../site/scripts/utils";
import { Message } from "../../site/scripts/Message";
import { Utils } from "../../site/scripts/utils";
import { Cart } from "./Cart";

interface CartItem {
  channelId: number;
  collection?: string;
  colorName?: string;
  colorNumber?: string;
  colors?: CartItem[] | string[];
  hexColor?: string;
  id: number;
}

interface AskValAPIResponse {
  cart: CartItem[];
  cartItems: CartItem[];
  favorites: CartItem[];
  id?: number;
  requestStatus?: string;
  token?: string;
}

export enum ChannelId {
  lowes = 1001,
  "lowes_ca" = 1002,
  ir = 1003,
  hgsw = 1006,
  minwax = 1007,
  krylon = 1008,
}

export enum LocaleId {
  "en_US" = 1001,
  "en_CA" = 1002,
  "es_MX" = 1004,
  "fr_CA" = 1005,
}

enum Verbs {
  addToCart = "cart/add",
  removeFromCart = "cart/delete",
  getCart = "cart/get",
  getToken = "user/token",
  addFavorite = "cart/add-favorite",
  addFavoriteCollection = "cart/add-favorite-collection",
}

class AskVal {
  domain: string;
  cartCookie = "cart-api";
  viewedCartCookie = "last-cart-view";
  staleCookie = "cart-stale-count";
  staleCookieCount = 0;
  stalenessThreshold = 5;
  private tokenCookie = "_avu";
  host: string;
  private token: string;
  private pathPrefix = "api/v2";
  private pathPrefixV3 = "api/v3";
  brand: string;

  constructor(apiEndpoint = "https://qa-orders.valspar.com") {
    const cart = document.querySelector("[data-cbg-cmp='cart']");

    this.domain = this.getDomain(location.hostname);
    this.brand = Utils.getCbgBrand();

    this.host = apiEndpoint;
    this.token = Cookie.get(this.tokenCookie) || null;

    // Do not execute logic if the cart is not present.
    if (!cart) return;

    // Get the cart data.
    const cartCookieData = Cookie.get(this.cartCookie);

    // Get the time that the user last viewed their cart page.
    const cartLastViewed = window.localStorage.getItem(this.viewedCartCookie);
    const forceGetCart = cartLastViewed !== null;

    // Get the cart staleness cookie count.
    const staleCookieCount = parseInt(Cookie.get(this.staleCookie));
    const cartIsStale = !isNaN(staleCookieCount)
      ? staleCookieCount >= this.stalenessThreshold
      : false;

    // If the cart data does NOT exist OR the user viewed their cart recently, then make a request to getCart.
    if (!cartCookieData || forceGetCart || !cartIsStale) {
      this.getCart();
      if (forceGetCart) {
        window.localStorage.removeItem(this.viewedCartCookie);
      }
    }

    this.registerEventHandlers();
  }

  private getDomain(hostName: string): string {
    if (!hostName) return hostName;

    const domainSegments = hostName.split(".");
    const lastIndex = domainSegments.length - 1;

    return lastIndex > 0
      ? `${domainSegments[lastIndex - 1]}.${domainSegments[lastIndex]}`
      : hostName;
  }

  private globalClickHandler(event) {
    const href = (event.target as HTMLElement)?.getAttribute("href") || "";
    const isOrdersLink = href.indexOf(`orders.${this.domain}`) > -1;
    // If the link is an order link, then set a value in the local storage.
    if (isOrdersLink) {
      window.localStorage.setItem(this.viewedCartCookie, `${new Date()}`);
    }
  }

  private registerEventHandlers() {
    window.addEventListener("click", this.globalClickHandler.bind(this));
  }

  private static getIdList(items: []): string {
    const sortedItems = items.sort(AskVal.sortCartItems);
    return sortedItems.map((item: Record<string, number>) => item.id).join("~");
  }

  private static sortCartItems(a, b): number {
    const aID = a.colorId || 0;
    const bID = b.colorId || 0;
    return aID - bID;
  }

  static getLocaleId(language: string, country: string): string {
    if (!language || !country) return;

    const locale = `${language}_${country.toUpperCase()}`;

    // use es_MX for US Spanish
    return locale === "es_US" ? LocaleId["es_MX"] : LocaleId[locale];
  }

  static getRetailerCookie(brand: string): string {
    return Cookie.get(`cbg:${brand}/cookies/retailer`);
  }

  static getChannel(
    retailerCookie: string,
    brand: string,
    country: string
  ): string {
    if (!retailerCookie && brand !== "valspar") return brand;

    const channel =
      retailerCookie?.substring(retailerCookie.lastIndexOf("/") + 1) || "lowes";

    if (channel === "independent-retailer") return "ir";

    if (channel === "lowes" && country === "ca") {
      return `${channel}_${country}`;
    }

    return channel;
  }

  public static getChannelId(brand: string, country: string): string {
    const retailerCookie = AskVal.getRetailerCookie(brand);

    return ChannelId[AskVal.getChannel(retailerCookie, brand, country)];
  }

  private checkStaleness(response) {
    const { cartItems = [] } = response;

    // Save a list of ids form the cart items.
    const idsList = AskVal.getIdList(cartItems);

    // Pull out the saved response from the cookie.
    const savedResponse = Cookie.get(this.cartCookie) || "{}";
    const savedCartItems = (JSON.parse(savedResponse) || {})["cartItems"] || [];

    // Save a list of ids from the saved items.
    const savedIdsList = AskVal.getIdList(savedCartItems);

    // If both sets match, then the cart response is stale and set the cookie.
    const isCartStale = idsList === savedIdsList;

    if (isCartStale) {
      this.staleCookieCount++;
      this.setStalenessCookie();
    }
  }

  private getVerbsKeyByValue(enumValue: Verbs): string {
    return Object.entries(Verbs).find(([key, value]) => {
      return value === enumValue ? key : null;
    })[0];
  }

  /*
   * Trims unused data in color array, returning a string array of hex colors
   * */
  private transformColorData(responseItems: CartItem[]): CartItem[] {
    return responseItems.map((item) => {
      if (!item.colors) return item;

      item.colors = item.colors.map((item) => item.hexColor);

      return item;
    });
  }

  /*
   * Transforms the response to meet the view requirements and not exceed cookie max size
   * */
  private transformResponseData(
    response: AskValAPIResponse
  ): AskValAPIResponse {
    // make copy of object before mutating
    const transformedResponse: AskValAPIResponse = JSON.parse(
      JSON.stringify(response)
    );

    transformedResponse.cart = this.transformColorData(
      transformedResponse.cart
    ).reverse();
    transformedResponse.cartItems = this.transformColorData(
      transformedResponse.cartItems
    ).reverse();
    transformedResponse.favorites = this.transformColorData(
      transformedResponse.favorites
    ).reverse();

    return transformedResponse;
  }

  async getCart(): Promise<AskValAPIResponse> {
    //  Check to see if a token exists and if not fetch and set one.
    if (!this.token) {
      await this.setToken();
    }
    const url = this.getUrlForVerb(Verbs.getCart);
    try {
      const response = await fetch(url, {
        cache: "no-cache",
      }).then((res: Response) => res.json());

      this.checkStaleness(response);

      const transformedResponse = this.transformResponseData(response);

      // Update the cookie.
      this.updateCookie(
        transformedResponse,
        this.getVerbsKeyByValue(Verbs.getCart)
      );

      // Broadcast a message with the response.
      Utils.msg.publish(Message.getCartResponse, transformedResponse);

      return transformedResponse;
    } catch (error) {
      console.error(error);
    }
  }

  async addToCart(
    itemId: string,
    channelId: string,
    localeId?: string,
    collectionName?: string
  ): Promise<AskValAPIResponse> {
    // If there is no item provided, do not continue.
    if (!itemId) {
      console.warn("Cannot add to Cart :: no item provided");
      return null;
    }

    //  Check to see if a token exists and if not fetch and set one.
    if (!this.token) {
      await this.setToken();
    }

    //  Now, send the request to add the item to the cart.
    const url = this.getUrlForVerb(
      Verbs.addToCart,
      itemId,
      channelId,
      localeId,
      collectionName
    );

    //  Return the response.
    try {
      const response = await fetch(url, {
        method: "POST",
      });

      const data = await response.json();
      // Remove unnecessary data from response
      const transformedResponse = this.transformResponseData(data);

      console.debug("Contact us response:", data);
      if (response.ok || (response.status >= 200 && response.status < 400)) {
        // Update the cookie.
        this.updateCookie(
          transformedResponse,
          this.getVerbsKeyByValue(Verbs.addToCart)
        );

        // Update the staleness cookie.
        this.staleCookieCount = 0;
        this.setStalenessCookie();

        // Broadcast a message with the response.
        Utils.msg.publish(Message.addToCartResponse, transformedResponse);

        return transformedResponse;
      } else {
        console.log("addToCard() failed - Err 1A");
        this.showFailedMessage("add");
      }
    } catch (err) {
      console.log("addToCard() failed - Err 1B");
      this.showFailedMessage("add");
      throw err;
    }
  }

  async removeFromCart(itemId: number): Promise<AskValAPIResponse> {
    // If there is no item provided, do not continue.
    if (!itemId || itemId < 1) {
      console.warn("Cannot remove from Cart :: no valid item provided");
      return null;
    }
    //  Check to see if a token exists and if not fetch and set one.
    if (!this.token) {
      await this.setToken();
    }

    const url = this.getUrlForVerb(Verbs.removeFromCart);

    try {
      const response = await fetch(`${url}/false`, {
        method: "POST",
        body: `id=${itemId}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      const data = await response.json();

      console.debug("Contact us response:", data);
      if (response.ok || (response.status >= 200 && response.status < 400)) {
        // Remove unnecessary data from response
        const transformedResponse = this.transformResponseData(data);

        // Update the cookie.
        this.updateCookie(
          transformedResponse,
          this.getVerbsKeyByValue(Verbs.removeFromCart)
        );

        // Update the staleness cookie.
        this.staleCookieCount = 0;
        this.setStalenessCookie();

        // Broadcast a message with the response.
        Utils.msg.publish(Message.removeFromCartResponse, transformedResponse);

        return transformedResponse;
      } else {
        console.log("removeFromCart() failed - Err 2A");
        this.showFailedMessage("remove");
      }
    } catch (err) {
      console.log("removeFromCart() failed - Err 2B");
      this.showFailedMessage("remove");
      throw err;
    }
  }

  async addFavorite(
    itemId: string,
    favoriteType: "color" | "collection",
    channelId: string,
    localeId?: string
  ): Promise<AskValAPIResponse> {
    // If there is no item provided, do not continue.
    if (!itemId) {
      console.warn("Cannot add a favorite :: no item provided");
      return null;
    }

    //  Check to see if a token exists and if not fetch and set one.
    if (!this.token) {
      await this.setToken();
    }

    // Determine if favorite is a color or collection and set associated verb
    const verb =
      favoriteType === "color"
        ? Verbs.addFavorite
        : Verbs.addFavoriteCollection;
    //  Now, send the request to add the item to the cart or fav
    const url = this.getUrlForVerb(verb, itemId, channelId, localeId);

    //  Return the response.
    const response = await fetch(url, {
      method: "POST",
    }).then((res) => res.json());

    // Remove unnecessary data from response
    const transformedResponse = this.transformResponseData(response);

    // Update the cookie.
    this.updateCookie(transformedResponse, this.getVerbsKeyByValue(verb));

    // Update the staleness cookie.
    this.staleCookieCount = 0;
    this.setStalenessCookie();

    // Broadcast a message with the response.
    Utils.msg.publish(Message.addFavoritesResponse, transformedResponse);

    return transformedResponse;
  }

  async removeFromFavorites(itemId: number): Promise<AskValAPIResponse> {
    // If there is no item provided, do not continue.
    if (!itemId || itemId < 1) {
      console.warn("Cannot remove from Favorites :: no valid item provided");
      return null;
    }
    const response = await this.removeFromCart(itemId);

    // Broadcast a message with the response.
    Utils.msg.publish(Message.removeFromFavoritesResponse, response);

    return response;
  }

  private getUrlForVerb(
    verb: Verbs,
    item?: string,
    channelId?: string,
    localeId?: string,
    collectionName?: string
  ) {
    // Don't append API token if the verb is token.
    if (verb === Verbs.getToken)
      return `${this.host}/${this.pathPrefix}/${verb}`;

    // Append the API token for all other requests.
    const urlChunks: Array<string> = [
      this.host,
      this.pathPrefix,
      verb,
      this.token,
      item,
      channelId,
      localeId,
      collectionName,
    ].filter(Boolean);

    return urlChunks.join("/");
  }

  private async setToken() {
    const tenYearsInDays = 10 * 365;
    const url = this.getUrlForVerb(Verbs.getToken);

    try {
      this.token = await fetch(url).then((response) => response.text());

      Cookie.setWithDomain(
        this.tokenCookie,
        this.token,
        tenYearsInDays,
        this.domain
      );
    } catch (error) {
      console.error(error);
    }
  }

  private updateCookie(response, verb) {
    // TODO: better handle when a response is a failed response.
    const { favorites = [], cartItems = [], id } = response;
    const dataToSet = {
      verb,
      favorites,
      cartItems,
      cartId: id,
    };

    Cookie.setWithDomain(
      this.cartCookie,
      JSON.stringify(dataToSet),
      1,
      this.domain
    );
  }

  private setStalenessCookie() {
    Cookie.setWithDomain(
      this.staleCookie,
      `${this.staleCookieCount}`,
      1,
      this.domain
    );
  }

  private showFailedMessage(action) {
    Cart.showModal();

    const messageElem = document.querySelector(
      ".cart--content-container__message"
    );

    switch (action) {
      case "add":
        messageElem.textContent =
          "Error - Please refresh and try to add chips again";
        break;
      case "remove":
        messageElem.textContent =
          "Error - Please refresh and try to remove chips again";
        break;
      default:
        messageElem.textContent = "Error - Please refresh and try again";
    }
  }
}

const cartElement = document.querySelector<HTMLElement>(
  '[data-cbg-cmp="cart"]'
);
const apiEndpoint = cartElement?.dataset.endpoint;

const CartAPI = new AskVal(apiEndpoint);

export { AskVal, CartAPI, CartItem };
