class ProductSpecs {
  component: HTMLElement;
  loadMoreBtn: HTMLButtonElement;
  tableRows: NodeListOf<HTMLElement>;
  limitRows = false;
  tableHeadCells: NodeListOf<HTMLTableHeaderCellElement>;

  constructor(component: HTMLElement) {
    this.component = component;

    if (!this.component) {
      return;
    }

    this.loadMoreBtn = this.component.querySelector(
      '[data-cbg-cmp-hook-productspecs="loadMoreButton"]'
    );

    this.tableHeadCells = this.component.querySelectorAll("th");
    if (this.loadMoreBtn) {
      const numberRowsToShow = this.loadMoreBtn?.dataset.rows;
      const numberOfRows = parseInt(numberRowsToShow);

      this.tableRows = this.component.querySelectorAll(".product-specs-row");
      if (this.tableRows && numberOfRows >= this.tableRows.length) {
        const btnEl = this.component.querySelector(".button-container");
        btnEl.classList.add("hidden");
      }

      this.tableRows.forEach((row, idx) => {
        if (idx >= numberOfRows) {
          row.classList.add("hidden");
        }
      });
    }
    this.registerEventHandlers();
  }

  private showMore() {
    if (this.tableRows.length) {
      this.tableRows.forEach((row) => {
        row.classList.remove("hidden");
      });
    }

    const btnEl = this.component.querySelector(".button-container");
    btnEl.classList.add("hidden");
  }

  private manageSortIcon(event: MouseEvent | KeyboardEvent) {
    const currentCell = <HTMLElement>event.target;
    const currentCellText = currentCell.innerText;
    document.querySelectorAll("table .header_arrow").forEach((spanElement) => {
      if (spanElement.parentElement.innerText !== currentCellText) {
        spanElement.classList.add("sorting");
      } else {
        spanElement.classList.remove("sorting");
      }
    });
  }

  private manageSortEnter(event: KeyboardEvent) {
    if (event.key === "Enter") {
      (event.currentTarget as HTMLElement).click();
    }
  }

  private registerEventHandlers() {
    if (this.loadMoreBtn) {
      this.loadMoreBtn.addEventListener("click", this.showMore.bind(this));
    }
    if (this.tableHeadCells) {
      this.tableHeadCells.forEach((tableHeadCell) => {
        tableHeadCell.addEventListener("click", this.manageSortIcon.bind(this));
        tableHeadCell.addEventListener(
          "keydown",
          this.manageSortEnter.bind(this)
        );
      });
    }
  }
}

export { ProductSpecs };
