import { Message } from "../../site/scripts/Message";
import { Cookie, Utils } from "../../site/scripts/utils";
import { AskVal, CartAPI, CartItem } from "./AskVal";

interface CartResponse {
  cart: CartItem[];
  cartItems: CartItem[];
  favorites: CartItem[];
}

type CartCtaState = "add" | "remove";

enum Classnames {
  displayNone = "display-none",
  iconAdd = "icon-add",
  iconRemove = "icon-remove",
  loading = "loading",
  stateAdd = "cart-cta--add-state",
  stateRemove = "cart-cta--add-remove",
}

enum Selector {
  element = "data-component-id",
  hook = "data-cbg-cmp-hook-cart-cta",
}

class CartCTA {
  //Elements
  chipIcon: HTMLElement;
  chipTextAdd: HTMLElement;
  chipTextRemove: HTMLElement;
  component: HTMLElement;

  // Properties
  apiCollectionName: string;
  brand: string;
  cartCtaState: CartCtaState;
  cartResponseCookie: CartResponse;
  channelId: string;
  collection: string;
  collectionName: string;
  colorCartId: number;
  colorId: string;
  colorName: string;
  country: string;
  language: string;
  locale: string;
  tags: string;

  constructor(component: HTMLElement) {
    this.component = component;

    if (!this.component) {
      return;
    }

    const dataset = this.component.dataset;

    this.apiCollectionName = dataset.apiCollectionName;
    this.brand = dataset.brand || "valspar";
    this.channelId = dataset.channelId;
    this.collection = dataset.collection;
    this.collectionName = dataset.collectionName;
    this.colorId = dataset.colorId;
    this.colorName = dataset.colorName || this.colorId;
    this.country = dataset.country || "us";
    this.language = dataset.language || "en";
    this.locale = dataset.locale;
    this.tags = dataset.colorTags;

    this.component.setAttribute("data-cmp-init", "true");

    this.cartResponseCookie = {
      cart: [],
      cartItems: [],
      favorites: [],
    };

    const cookieValue = Cookie.get(CartAPI.cartCookie);

    if (cookieValue) {
      this.cartResponseCookie = JSON.parse(cookieValue);
    }

    this.initializeUI();
    this.registerEventHandlers();
    this.registerMessageSubscribers();

    // Update the component state based on the value in the cookie.
    this.updateComponentState(this.cartResponseCookie);
  }

  private initializeUI(): void {
    this.chipTextAdd = this.component.querySelector(
      `[${Selector.hook}="chip-text-add"]`
    );

    this.chipTextRemove = this.component.querySelector(
      `[${Selector.hook}="chip-text-remove"]`
    );

    this.chipIcon = this.component.querySelector(
      `[${Selector.hook}="chip-icon"]`
    );
  }

  private registerEventHandlers(): void {
    this.component?.addEventListener("click", this.initiateRequest.bind(this));
  }

  private registerMessageSubscribers(): void {
    // Subscribe to the color chip update publisher.
    Utils.msg.subscribe(
      Message.addToCartResponse,
      this.updateComponentState.bind(this)
    );

    Utils.msg.subscribe(
      Message.removeFromCartResponse,
      this.updateComponentState.bind(this)
    );

    Utils.msg.subscribe(
      Message.getCartResponse,
      this.updateComponentState.bind(this)
    );
  }

  private updateComponentState(response: CartResponse): void {
    // Check whether the color is in the "cart" array.
    const cartItems = response.cartItems || [];
    const itemFromCartItems = (
      cartItems.filter(
        (item) =>
          item.colorNumber === this.colorId ||
          item.colorNumber === this.collection
      ) || []
    ).pop();
    const isInCartItems =
      itemFromCartItems != null || typeof itemFromCartItems != "undefined";

    // Update the state of the button.
    this.cartCtaState = isInCartItems ? "remove" : "add";

    // Update the UI of the button.
    this.updateUI(this.cartCtaState);

    // Update the colorCartId, if needed.
    this.colorCartId = itemFromCartItems?.id || 0;

    // Remove the loading class.
    this.component.classList.remove(Classnames.loading);
  }

  private updateUI(cartCtaState: CartCtaState): void {
    const inverseCartCtaState: CartCtaState =
      cartCtaState === "add" ? "remove" : "add";

    // Clean up for proper class state, need to look at diff for better solution
    this.component?.classList[cartCtaState](Classnames.stateAdd);
    this.component?.classList[inverseCartCtaState](Classnames.stateRemove);

    // Show the add text.
    this.chipTextAdd?.classList[inverseCartCtaState](Classnames.displayNone);

    // Hide the remove text.
    this.chipTextRemove?.classList[cartCtaState](Classnames.displayNone);

    // Update the icon class.
    this.chipIcon?.classList[cartCtaState](Classnames.iconAdd);
    this.chipIcon?.classList[inverseCartCtaState](Classnames.iconRemove);
  }

  private initiateRequest(): void {
    // Add a loading class
    this.component.classList.add(Classnames.loading);

    // Make the request based on the state.
    let dataToSend = null;

    switch (this.cartCtaState) {
      case "add":
        dataToSend = this.colorId || null;

        if (dataToSend == null && this.collection?.length > 0) {
          dataToSend = this.collection;
          this.colorName = this.collection;
        }

        if (dataToSend) {
          if (this.channelId == null) {
            this.channelId = AskVal.getChannelId(this.brand, this.country);
          }

          // Send a message that this color is being added.
          Utils.msg.publish(Message.addToCart, { colorName: this.colorName });

          // Send the request to the API.
          CartAPI.addToCart(
            dataToSend,
            this.channelId,
            this.locale || AskVal.getLocaleId(this.language, this.country),
            this.apiCollectionName
          ).then((res) => console.log({ res }));
        }
        break;
      case "remove":
        // Send a message that this color is being removed.
        Utils.msg.publish(Message.removeFromCart, {
          colorName: this.colorName,
        });

        // Send the request to the API.
        CartAPI.removeFromCart(+this.colorCartId).then((res) =>
          console.log({ res })
        );
        break;
      default:
        console.warn(
          "Couldn't figure out the button state. No request sent!",
          this.cartCtaState
        );
    }
  }
}

export { CartCTA };
