enum Selector {
  hook = "data-cbg-cmp-hook-image-upload",
}

class ImageUpload {
  container: HTMLElement;
  uploadBtn: HTMLElement;
  inputEl: HTMLInputElement;
  imageEl: HTMLImageElement;
  uploadTxt: HTMLElement;
  uploadIcon: HTMLElement;
  cancelIcon: HTMLElement;
  pdfIcon: HTMLElement;
  filename: HTMLElement;
  fileNameError: HTMLElement;
  imageUploadError: HTMLElement;
  fileError: HTMLElement;
  imageContainer: HTMLElement;
  requiredMessageSpan: HTMLSpanElement;
  imageUploadRequired: boolean;
  private parentForm: HTMLFormElement;

  constructor(component: HTMLElement) {
    // Elements
    this.container = component;
    if (!this.container) {
      return null;
    }

    this.inputEl = this.container.querySelector(
      `[${Selector.hook}="imageupload-input"]`
    );
    this.parentForm = this.inputEl.form;
    this.requiredMessageSpan = this.container.querySelector(
      ".image-required-message"
    );

    this.imageUploadRequired =
      this.container.dataset.imageuploadRequired === "true";

    this.registerEventHandlers();
  }

  private registerEventHandlers() {
    this.parentForm.addEventListener(
      "submit",
      this.validateImageUpload.bind(this)
    );

    this.inputEl.type = "file";
    this.inputEl.accept = "image/x-png,image/jpeg,application/pdf";
    this.inputEl.addEventListener(
      "change",
      this.validateImageUpload.bind(this)
    );

    this.uploadBtn = this.container.querySelector(
      `[${Selector.hook}="imageupload-button"]`
    );

    this.imageEl = this.container.querySelector(
      `[${Selector.hook}="imageupload-image"]`
    );
    this.uploadTxt = this.container.querySelector(
      `[${Selector.hook}="imageupload-btn-text"]`
    );
    this.uploadIcon = this.container.querySelector(
      `[${Selector.hook}="imageupload-icon"]`
    );
    this.cancelIcon = this.container.querySelector(
      `[${Selector.hook}="imageupload-cancel-icon"]`
    );
    this.filename = this.container.querySelector(
      `[${Selector.hook}="imageupload-filename"]`
    );
    this.fileError = this.container.querySelector(
      `[${Selector.hook}="imageupload-error"]`
    );
    this.fileNameError = this.container.querySelector(
      `[${Selector.hook}="filename-error"]`
    );
    this.pdfIcon = this.container.querySelector(
      `[${Selector.hook}="imageupload-pdf-icon"]`
    );
    this.imageContainer = this.container.querySelector(
      `[${Selector.hook}="imageupload-image-container"]`
    );

    this.inputEl.addEventListener("click", this.uploadImage.bind(this));
    this.cancelIcon.addEventListener(
      "click",
      this.removeImageUpload.bind(this)
    );
  }

  private uploadImage() {
    const imageElementSrc = this.imageEl;
    const el = this.inputEl;
    const btnText = this.uploadTxt;
    const btnIcon = this.uploadIcon;
    const cancelIcon = this.cancelIcon;
    const pdfIcon = this.pdfIcon;
    const fileNameHolder: any = this.filename;
    const fileError = this.fileError;
    const imageContainer = this.imageContainer;
    const fileNameError = this.fileNameError;
    const imageUploadError = this.imageUploadError;
    el.addEventListener("change", function () {
      if (el.files.length) {
        if (el.files[0].size > 10000000) {
          fileError.style.display = "inline";
        } else {
          imageContainer.style.display = "inline";
          fileError.style.display = "none";
          const fileName = el.files[0].name;
          btnText.style.display = "none";
          btnIcon.style.display = "none";
          cancelIcon.classList.add("flex-it");
          pdfIcon.style.display = "none";
          imageElementSrc.src = URL.createObjectURL(el.files[0]);
          if (fileName !== null) {
            fileNameHolder.innerHTML = fileName;
          }
          if (fileName === null) {
            fileNameError.style.display = "inline";
            fileNameHolder.innerHTML = fileError;
          }
          if (el.files[0].type == "application/pdf") {
            imageElementSrc.style.display = "none";
            pdfIcon.style.display = "block";
          } else {
            imageElementSrc.style.display = "block";
          }
        }
      }
    });
    this.validateImageUpload.bind(this);
  }

  private removeImageUpload() {
    console.log("fired from remove");
    this.inputEl.value = "";
    const imageContainer = this.imageContainer;
    const imageElementSrc = this.imageEl;
    const btnText = this.uploadTxt;
    const btnIcon = this.uploadIcon;
    const cancelIcon = this.cancelIcon;
    const pdfIcon = this.pdfIcon;
    imageContainer.style.display = "none";
    imageElementSrc.src = "";
    btnText.style.display = "block";
    btnIcon.style.display = "block";
    cancelIcon.classList.remove("flex-it");
    pdfIcon.style.display = "none";
    this.filename.innerHTML = "";
    this.validateImageUpload();
  }

  private validateImageUpload() {
    const requiredMessage = this.requiredMessageSpan;
    if (this.imageUploadRequired && this.inputEl.files.length < 1) {
      requiredMessage.style.display = "block";
      return false;
    }
    requiredMessage.style.display = "none";
  }
}

export { ImageUpload };
