import { Cpra } from "../../site/scripts/cpra";

class NavigationV2 {
  mainNav: HTMLElement;
  cpra: Cpra;

  constructor(component: HTMLElement) {
    if (!component) return;
    this.mainNav = document
      .getElementsByClassName("navigationV2")
      .item(0) as HTMLElement;

    this.cpra = new Cpra();

    window.onload = this.checkForMobile;
    window.onresize = this.checkForMobile;

    const headerSearchInputContainer = document.querySelector(
      ".header-home .search-wrapper .search-zone .cmp-searchbar__form-container"
    );

    const headerSearchInput = document.querySelector(
      ".header-home .search-wrapper .search-zone .cmp-searchbar__input"
    );

    const headerSearchZone = document.querySelector(
      ".header-home .search-wrapper .search-zone"
    );

    headerSearchInput.addEventListener("focus", function () {
      headerSearchInput.classList.add("header-search-input-active");
      headerSearchInputContainer.classList.add(
        "header-search-container-active"
      );
      headerSearchZone.classList.add("header-search-zone-active");
    });

    headerSearchInput.addEventListener("blur", function () {
      headerSearchInput.classList.remove("header-search-input-active");
      headerSearchInputContainer.classList.remove(
        "header-search-container-active"
      );
      headerSearchZone.classList.remove("header-search-zone-active");
    });

    //click that remove open search
    document.addEventListener("click", (e) => {
      const isClickedInsideDiv = e
        .composedPath()
        .includes(headerSearchInputContainer);
      if (isClickedInsideDiv) {
        //console.log('clicked inside of div')
      } else {
        //console.log('clicked outside of div')
        headerSearchInput.classList.remove("header-search-input-active");
        headerSearchInputContainer.classList.remove(
          "header-search-container-active"
        );
        headerSearchZone.classList.remove("header-search-zone-active");
      }
    });
  }

  private checkForMobile = () => {
    //console.log(window.innerWidth);

    if (window.innerWidth <= 768) {
      // uncomment to debug
      //console.log("mobile breakpoint db ts");

      // move utility nav to mobile nav
      const mobileUtilityNavMove: HTMLElement = document.querySelector(
        "nav .main-menu-list .authored-list-listItem"
      );
      if (
        typeof mobileUtilityNavMove != "undefined" &&
        mobileUtilityNavMove != null
      ) {
        // Exists.
      } else {
        const utilityZoneList = document.querySelectorAll(
          ".header div.utility-zone ul.authored-list-list li"
        );
        const mainNavigationListUl = document.querySelector(
          "nav ul.main-menu-list"
        );

        utilityZoneList.forEach((li) => {
          mainNavigationListUl.appendChild(li);
        });
      }

      //move favorites to main nav
      const mobileFavoritesNavItem = document.querySelector(
        "nav .mobile-favorites-nav-item"
      );
      if (
        typeof mobileFavoritesNavItem != "undefined" &&
        mobileFavoritesNavItem != null
      ) {
        // Exists.
      } else {
        const favoritesCreateLi = document.createElement("li");
        const mainNavigationList = document.querySelector(
          "nav ul.main-menu-list"
        );
        favoritesCreateLi.className = "mobile-favorites-nav-item";
        const favoritesSection = document.querySelector(
          ".cbg-cmp-favorites.context--header"
        );
        mainNavigationList.appendChild(favoritesCreateLi);
        favoritesCreateLi.appendChild(favoritesSection);
      }

      const mobileNavBackLevelButton = document.querySelector(
        "nav .mobile-nav-back-level-button"
      );
      if (
        typeof mobileNavBackLevelButton != "undefined" &&
        mobileNavBackLevelButton != null
      ) {
        // Exists.
      } else {
        //add back button then add class name to back button
        const mobileBackButton = document.createElement("div");
        mobileBackButton.className = "mobile-nav-back-level-button";
        mobileBackButton.tabIndex = 0;

        //below should be moved to data element for translation text
        const backButtonText = document.createTextNode("Back");
        mobileBackButton.appendChild(backButtonText);

        //find nav button and insert back button after
        const navigationContainer = document.querySelector(
          "div.navigationV2 .main-menu-navigation"
        );
        const navigationHamburgerMenu = document.querySelector(
          "div.navigationV2 .main-menu-mobile-toggle-button"
        );
        navigationContainer.insertBefore(
          mobileBackButton,
          navigationHamburgerMenu.nextSibling
        );
      }

      //create mobile search toggle button
      const mobileSearchBackground = document.querySelector(
        ".search-zone .header-search-bg"
      );
      if (
        typeof mobileSearchBackground != "undefined" &&
        mobileSearchBackground != null
      ) {
        // mobileSearchBackground Exists.
      } else {
        const searchForm = document.querySelector(
          ".search-zone .cmp-searchbar__form"
        );
        //create searchform background mobile
        const searchFormOverlay = document.createElement("div");
        searchFormOverlay.className = "header-search-bg";
        searchForm.appendChild(searchFormOverlay);
      }

      const mobileSearchToggleButton = document.querySelector(
        ".search-zone .header-search-toggle-btn"
      );
      if (
        typeof mobileSearchToggleButton != "undefined" &&
        mobileSearchToggleButton != null
      ) {
        // mobileSearchToggleButton Exists.
      } else {
        const searchForm = document.querySelector(
          ".search-zone .cmp-searchbar__form"
        );
        const headerSearchToggle = document.createElement("div");
        headerSearchToggle.className = "header-search-toggle-btn";
        headerSearchToggle.tabIndex = 0;
        searchForm.appendChild(headerSearchToggle);

        const headerSearchInputContainer = document.querySelector(
          ".header-home .search-wrapper .search-zone .cmp-searchbar__form-container"
        );
        headerSearchToggle.addEventListener("click", function () {
          headerSearchInputContainer.classList.add(
            "header-search-container-active"
          );
        });

        document.addEventListener("click", (e) => {
          const isClickedInsideDiv = e
            .composedPath()
            .includes(headerSearchInputContainer);
          if (isClickedInsideDiv) {
            //console.log('clicked inside of div')
          } else {
            //console.log('clicked outside of div')
            headerSearchInputContainer.classList.remove(
              "header-search-container-active"
            );
          }
        });
      }

      return;
    } else {
      // All desktop movement
      //console.log("desktop breakpoint");
      if (document.querySelector("nav .mobile-nav-back-level-button")) {
        //remove mobile back button if it's there
        const navMobileBackButtonRemove = document.querySelector(
          "nav .mobile-nav-back-level-button"
        );
        navMobileBackButtonRemove.remove();
      }

      //create searchform background desktop
      //create desktop search toggle button
      const desktopSearchBackground = document.querySelector(
        ".search-zone .header-search-bg"
      );
      if (
        typeof desktopSearchBackground != "undefined" &&
        desktopSearchBackground != null
      ) {
        // mobileSearchBackground Exists.
      } else {
        const searchForm = document.querySelector(
          ".search-zone .cmp-searchbar__form"
        );
        const searchZone = document.querySelector(".search-zone");
        const searchFormOverlay = document.createElement("div");
        searchFormOverlay.className = "header-search-bg";
        searchZone.appendChild(searchFormOverlay);
      }

      // move utility nav back to desktop
      const mobileUtilityMobileNavMove = document.querySelector(
        "nav .main-menu-list .authored-list-listItem"
      );
      if (
        typeof mobileUtilityMobileNavMove != "undefined" &&
        mobileUtilityMobileNavMove != null
      ) {
        //console.log("authored list is in mobile menu");
        //move authored list back

        const utilityZoneMobileMenuList = document.querySelectorAll(
          "nav .main-menu-list .authored-list-listItem"
        );
        const utilityZone = document.querySelector(
          ".utility-zone .authored-list-list"
        );

        utilityZoneMobileMenuList.forEach((li) => {
          utilityZone.appendChild(li);
        });
      } else {
        //console.log("authored list is in desktop menu");
      }

      // move favorites back to desktop
      const mobileFavoriteMobileNavItem = document.querySelector(
        "nav .main-menu-list .mobile-favorites-nav-item .cbg-cmp-favorites"
      );
      if (
        typeof mobileFavoriteMobileNavItem != "undefined" &&
        mobileFavoriteMobileNavItem != null
      ) {
        //console.log("favorites is in mobile menu");
        //move favorites back

        const mobileFavoriteMobileNavItemContent = document.querySelector(
          "nav .main-menu-list .mobile-favorites-nav-item .cbg-cmp-favorites"
        );
        const tertiaryFavoritesZone = document.querySelector(
          ".tertiary-zone .favorites"
        );

        tertiaryFavoritesZone.append(mobileFavoriteMobileNavItemContent);
        const mobileFavoritesNavItemRemove = document.querySelector(
          "nav .main-menu-list .mobile-favorites-nav-item"
        );
        mobileFavoritesNavItemRemove.remove();
      } else {
        //console.log("favorites is in desktop menu");
      }
    }
  };
}
export { NavigationV2 };
