import { CartCTA } from "../cart/CartCTA";
import {
  FavoritesAPI,
  FavoriteColorItem,
  ProductT,
} from "./FavoritesController";
import { FavoriteCTA } from "./FavoriteCTA";
import { Message } from "../../site/scripts/Message";
import { Utils, Cookie } from "../../site/scripts/utils";

interface CTA {
  new (element: HTMLElement): CartCTA | FavoriteCTA;
}

enum Classnames {
  displayNone = "display-none",
  loading = "loading",
  emptyContainer = "empty-container",
  visualizeButton = "favorites-visualizer-button",
}

enum Selector {
  component = "data-cbg-cmp",
  hook = "data-cmp-hook-favorites",
}

const Regex = {
  coty: /colou?r_of_the_year/,
  popular: /popular/,
};

class Favorites {
  addChipText: string;
  addAllChipsText: string;
  buySampleText: string;
  buySampleIconPath: string;
  buySampleIconPosition: string;
  colorFavCtaAriaLabel: string;
  context = "header";
  cotyBackground: string;
  cotyCtaText: string;
  cotyDescriptionText: string;
  cotyDetailUrlSegment: string;
  editMode = false;
  cdpLinkExtension = Utils.isExtensionRequired() ? ".html" : "";
  moreColorsText: string;
  postToApi: string;
  removeAllChipsText: string;
  removeChipText: string;
  textForCoty: string;

  // Elements
  component: HTMLElement;
  badgeCount: HTMLElement;
  badgeCountUtilityNav: HTMLElement;
  badgeCountVisualizer: HTMLElement;
  collectionsContent: HTMLElement;
  collectionsSection: HTMLElement;
  collectionsTemplate: HTMLTemplateElement;
  colorsContent: HTMLElement;
  colorsSection: HTMLElement;
  contentContainer: HTMLElement;
  emptyContentContainer: HTMLElement;
  populatedContentContainer: HTMLElement;
  currentBrand: string;
  dataVisualizerDesktop: string;
  dataVisualizerMobile: string;
  favContent: HTMLElement;
  visualizerURL: string;
  visualizerButtonContainer: HTMLElement;

  constructor(component: HTMLElement) {
    if (!component) return;

    this.component = component;

    const data = this.component.dataset;

    this.addChipText = data.addChipText || "";
    this.addAllChipsText = data.addAllChipsText || "";
    this.buySampleText = data.buySampleText || "";
    this.buySampleIconPath = data.buySampleIcon || "";
    this.buySampleIconPosition = data.buySampleIconPosition;
    this.colorFavCtaAriaLabel = data.colorFavCtaAriaLabel;
    this.context = data.context;
    this.cotyBackground = data.cotyImage;
    this.cotyCtaText = data.cotyCtaText || "";
    this.cotyDescriptionText = data.cotyDescription || "";
    this.cotyDetailUrlSegment = data.cotyDetailUrlSegment;
    this.editMode = data.editMode === "edit";
    this.moreColorsText = data.moreColors || "";
    this.postToApi = data.postToApi;
    this.removeChipText = data.removeChipText || "";
    this.removeAllChipsText = data.removeAllChipsText || "";
    this.textForCoty = data.textForCoty;
    this.currentBrand = Utils.getCbgBrand();
    this.dataVisualizerDesktop = data.colorVisualizerDesktop;
    this.dataVisualizerMobile = data.colorVisualizerMobile;
    this.visualizerURL = data.colorVisualizerUrl;

    this.setFavoriteStorageOptionCookie();
    FavoritesAPI.setPostToApi(this.postToApi);

    // Read favorites data from the FavoritesAPI.
    const savedFavorites = FavoritesAPI.read();

    if (this.context === "header") {
      this.initializeHeaderUI();
      this.updateHeaderUI(savedFavorites);
    }

    if (this.context === "page") {
      this.initializePageUI();
      this.updatePageUI(savedFavorites);
      FavoritesAPI.updateFavoriteCTAs();
    }

    this.registerMessageSubscribers();
  }

  private setFavoriteStorageOptionCookie(): void {
    if (this.postToApi) {
      Cookie.set("favoritesStorageOption", this.postToApi, 7);
    }
  }

  private initializeHeaderUI(): void {
    this.badgeCount = this.component.querySelector(
      `[${Selector.hook}="item-count--badge"]`
    );
    this.badgeCountUtilityNav = document.querySelector(".js-favorites-badge");
    //For Minwax Visualiser
    this.badgeCountVisualizer = document.querySelector(
      ".js-favorites-visualizer"
    );
  }

  private showHideBadgeCount(addOrRemove: "add" | "remove"): void {
    this.badgeCount?.classList[addOrRemove](Classnames.displayNone);
    this.badgeCountUtilityNav?.classList[addOrRemove](Classnames.displayNone);
    this.badgeCountVisualizer?.classList[addOrRemove](Classnames.displayNone);
  }

  private updateHeaderUI(favorites: FavoriteColorItem[]): void {
    const count = favorites?.length || 0;
    const countString = `&thinsp;(${count})`;

    if (this.badgeCount) {
      this.badgeCount.innerHTML = `${count}`;
    }

    if (this.badgeCountUtilityNav) {
      this.badgeCountUtilityNav.innerHTML = countString;
      const utilityNav = this.badgeCountUtilityNav.closest(
        ".cbg-cmp-utility-nav"
      );
      //Remove Fav-counter class when count is greater than 0 , the class releated CSS is added in respoctive brands
      utilityNav?.classList.toggle("favorite-counter", count > 0);
    }

    if (this.badgeCountVisualizer) {
      this.badgeCountVisualizer.innerHTML = countString;
      const favCountNav = this.badgeCountVisualizer.closest(
        ".cbg-cmp-favorites.context--header"
      );
      //Remove favorite-counter-nav class when count is greater than 0 , the class releated CSS is added in respoctive brands
      favCountNav?.classList.toggle("favorite-counter-nav", count > 0);
    }
    this.showHideBadgeCount(count > 0 ? "remove" : "add");
  }

  private initializePageUI(): void {
    this.contentContainer = this.component.querySelector(
      `[${Selector.hook}="content"]`
    );
    this.emptyContentContainer = this.component.querySelector(
      `[${Selector.hook}="empty-container"]`
    );
    this.populatedContentContainer = this.component.querySelector(
      `[${Selector.hook}="populated-container"]`
    );
    this.colorsSection = this.component.querySelector(
      `[${Selector.hook}="colors-section"]`
    );
    this.colorsContent = this.component.querySelector(
      `[${Selector.hook}="colors-content"]`
    );
    this.collectionsSection = this.component.querySelector(
      `[${Selector.hook}="collections-section"]`
    );
    this.collectionsContent = this.component.querySelector(
      `[${Selector.hook}="collections-content"]`
    );
    this.collectionsTemplate = this.component.querySelector(
      "#collection-card-favorites"
    );
    this.favContent = document.querySelector(".favorites--content");
    this.visualizerButtonContainer = this.component.querySelector(
      `[${Selector.hook}="visualizerButton"]`
    );
  }

  private updateElementVisibility(
    element: HTMLElement,
    shouldHide: boolean
  ): void {
    const addOrRemove: "add" | "remove" = shouldHide ? "add" : "remove";

    element?.classList[addOrRemove](Classnames.displayNone);
  }

  static getTagEmblemFromTag(tagList: string, regex: RegExp): string {
    const matchingTags = tagList.match(regex);

    if (matchingTags.length > 0) {
      return matchingTags[0].replace(/_/g, " ");
    }

    return null;
  }

  private setFavoriteTagEmblem(item: FavoriteColorItem): void {
    // Add a tag emblem property for "color of the year" or "popular" tags
    if (Regex.coty.test(item.tags || "")) {
      item.tagEmblem = Favorites.getTagEmblemFromTag(
        item.tags || "",
        Regex.coty
      );
    } else if (Regex.popular.test(item.tags || "")) {
      item.tagEmblem = Favorites.getTagEmblemFromTag(
        item.tags || "",
        Regex.popular
      );
    }
  }

  static renderTagEmblem(color: FavoriteColorItem): string {
    if (!color.tagEmblem) return "";

    return `
      <div class="cbg-colorCard__tagemblem">
          <span class="ccbg-colorCard__tagemblem-text">${color.tagEmblem}</span>
      </div>`;
  }

  private renderColorVisulaizerButton(color): string {
    if (!this.dataVisualizerMobile || !this.dataVisualizerDesktop) {
      return "";
    }

    return `
    <a href=${
      this.visualizerURL + color.mdmColorId
    } class="cbg-colorCard__cart-btn cart-cta--button"
            data-cbg-cmp="visualizer-color"
            ${color.colorName ? `data-color-name="${color.colorName}"` : ""}
            ${color.channelId ? `data-channel-id="${color.channelId}"` : ""}
            ${color.colorNumber ? `data-color-id="${color.colorNumber}"` : ""}
            ${color.colorId ? `data-color-id="${color.colorId}"` : ""}
            ${color.tags ? `data-color-tags="${color.tags}"` : ""}
            ${color.collection ? `data-collection="${color.collection}"` : ""}
            ${color.mdmColorId ? `data-mdmid="${color.mdmColorId}"` : ""}
            ${
              color.collectionName
                ? `data-collection-name="${color.collectionName}"`
                : ""
            }
            ${color.localeId ? `data-locale="${color.localeId}"` : ""}
            data-cbg-cmp-hook-color-tmpl="chip-btn">
    <span class="cbg-colorCard__text colorCard__visualizer desktop-only" data-cbg-cmp-hook-visualizer-cta="chip-text-remove">
             ${this.dataVisualizerDesktop}
           </span>
           <span class="cbg-colorCard__text colorCard__visualizer mobile-only" data-cbg-cmp-hook-visualizer-cta="chip-text-remove">
             ${
               this.dataVisualizerMobile
                 ? this.dataVisualizerMobile
                 : this.dataVisualizerDesktop
             }
      </span>
      <div class=" colorCard__visualizer__img cbg-colorCard-img"></div>
      </a>`;
  }

  private renderMinwaxFav(
    color: FavoriteColorItem,
    isCotySwatch,
    cotyStyleBlock,
    favStyleBlock,
    transparency,
    productName: string
  ): string {
    if (!color) return "";

    return `
        <div class="cbg-cmp-card__swatch-container swatch-container cbg-colorCard favorite-page-swatch">
            <div class="color-anchor">
                <a class="color-detail-link" href="${this.getColorDetailUrl(
                  color
                )}" >
                    <div class="cbg-color-swatch"
                         title="${color.colorName}"
                         style=${isCotySwatch ? cotyStyleBlock : favStyleBlock}>
                    </div>
                    <div class="cbg-colorCard__info"
                         data-tags="${color.tags || ""}">
                        <h4 class="cbg-colorCard__color-name">
                            ${color.colorName}
                        </h4>
                        <h5 class="cbg-colorCard__color-id">
                            ${
                              isCotySwatch
                                ? this.textForCoty
                                : color.colorNumber || color.colorId
                            }
                        </h5>
                          ${
                            productName
                              ? `<h5 class="cbg-colorCard__color-transparency">${productName}</h5>`
                              : ""
                          }
                        <h5 class="cbg-colorCard__color-transparency">
                            ${
                              transparency
                                ? color.transparency != "undefined"
                                : color.transparency || ""
                            }
                        </h5>
                        ${Favorites.renderTagEmblem(color)}
                    </div>
                </a>
                <button class="cbg-favorite-cta"
                           data-fav-id="${color.id}"
                           title="${color.colorName || color.collectionName}"
                           data-fav-color-id="${color.colorNumber}"
                           data-fav-color-name="${color.colorName}"
                           data-fav-channel-id="${color.channelId}"
                           data-fav-hex="${color.hex || color.hexColor}"
                           data-fav-tags="${color.tags}"
                           data-cbg-cmp="favorite-cta"
                           data-mdmid="${color.mdmColorId}"
                           ${this.renderAriaLabel(
                             this.colorFavCtaAriaLabel
                           )}></button>
            </div>
            ${this.renderColorVisulaizerButton(color)}
    </button>
        </div>
    `;
  }

  private renderCartButton(item): string {
    return `
        <button class="cbg-colorCard__cart-btn cart-cta--button"
                data-cbg-cmp="cart-cta"
                ${item.colorName ? `data-color-name="${item.colorName}"` : ""}
                ${item.channelId ? `data-channel-id="${item.channelId}"` : ""}
                ${item.colorNumber ? `data-color-id="${item.colorNumber}"` : ""}
                ${item.colorId ? `data-color-id="${item.colorId}"` : ""}
                ${item.tags ? `data-color-tags="${item.tags}"` : ""}
                ${item.collection ? `data-collection="${item.collection}"` : ""}
                ${
                  item.collectionName
                    ? `data-collection-name="${item.collectionName}"`
                    : ""
                }
                ${item.localeId ? `data-locale="${item.localeId}"` : ""}
                data-cbg-cmp-hook-color-tmpl="chip-btn">
            <span class="cart-cta--icon cbg-colorCard__icon icon-add" data-cbg-cmp-hook-cart-cta="chip-icon"></span>
            <span class="cbg-colorCard__text add-text" data-cbg-cmp-hook-cart-cta="chip-text-add">
                ${this.addChipText}
            </span>
            <span class="cbg-colorCard__text remove-text display-none" data-cbg-cmp-hook-cart-cta="chip-text-remove">
                ${this.removeChipText}
            </span>
        </button>
    `;
  }

  private renderExternalSampleLinkSVG(): string {
    return `
      <svg class="icon icon--external">
          <use href="${this.buySampleIconPath}"></use>
      </svg>
    `;
  }

  private renderBuySampleButton(color: FavoriteColorItem): string {
    const retailer = this.getRetailerSegment(color.channelId?.toString());
    if (retailer != "browse-colors/independent-retailers/") {
      if (!this.buySampleText.trim()) return "";

      document.querySelector("#tab-morningstar");

      return `
      <a class="standard-link--buy-sample"
         href="${color.paintSampleUrl || "#"}"
         target="_blank">
          <span class="buy-sample-content">
              ${
                this.buySampleIconPosition === "before"
                  ? this.renderExternalSampleLinkSVG()
                  : ""
              }
              ${this.buySampleText}
              ${
                this.buySampleIconPosition === "after"
                  ? this.renderExternalSampleLinkSVG()
                  : ""
              }
          </span>
      </a>
    `;
    } else {
      return "";
    }
  }

  private getRetailerSegment(channelId: string): string {
    let retailerSegment = "browse-colors/";

    switch (channelId) {
      case "1001":
      case "1002":
        retailerSegment += "lowes/";
        break;

      case "1003":
        retailerSegment += "independent-retailers/";
        break;

      default:
        retailerSegment = "";
    }

    return retailerSegment;
  }

  /**
   * This logic was ported from "core/src/main/java/com/cbg/platform/core/datafeed/writers/ColorWriter.java"
   */
  private singularizeColorFamily(colorFamily: string): string {
    let singularizedColorFamily = colorFamily;

    switch (colorFamily) {
      case "Azules":
      case "Marrones":
      case "Grises":
        singularizedColorFamily = colorFamily.slice(0, -2);
        break;
      case "Verde-Azules":
        singularizedColorFamily = colorFamily.slice(0, -2);
        break;
      case "Gris": //'Gris' is 'Gris', no removals in this case
        break;

      default:
        singularizedColorFamily = colorFamily.slice(0, -1);
        break;
    }

    return singularizedColorFamily;
  }

  private getColorFamilySegment(colorFamily: string): string {
    if (!colorFamily) return;

    // Strips accents from string: https://stackoverflow.com/a/37511463
    const normalizedColorFamily = colorFamily
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "")
      .replace(/\s/g, "-");

    if (normalizedColorFamily.endsWith("s")) {
      return `${this.singularizeColorFamily(normalizedColorFamily)}/`;
    }

    return `${normalizedColorFamily}/`;
  }

  private getColorDetailSegment(color: FavoriteColorItem): string {
    let colorNameKebabCase;

    if (this.currentBrand === "dutchboy") {
      colorNameKebabCase = color.colorName?.replace(/[^\w\s-]/g, "");
    } else {
      colorNameKebabCase = color.colorName?.replace(/[^\w\s]/g, ""); // Strip all characters from string except alphanumeric and space: https://stackoverflow.com/a/10253556
    }
    colorNameKebabCase = colorNameKebabCase.replace(/\s/g, "-"); // Replace spaces with hyphens

    if (color.mdmType == "Wood Stain" || color.mdmType == "Concrete Stain") {
      return `${colorNameKebabCase}-${color.transparency.toLowerCase()}`;
    } else if (
      color.channelId == "1007" &&
      (color.transparency == "Solid" ||
        color.transparency == "Semi-Transparent")
    ) {
      return `${colorNameKebabCase}-${
        color.colorNumber
      }-${color.transparency.toLowerCase()}`;
    } else {
      return `${colorNameKebabCase}-${color.colorNumber}`;
    }
  }

  private getColorDetailUrl(color: FavoriteColorItem): string {
    let colorsListSegment = "colors/";
    let retailerSegment;
    if (color.channelId == "1011") {
      retailerSegment = this.getColorPath(color.mdmType?.toString());
    } else if (color.channelId == "1007") {
      colorsListSegment = "wood-stain-color-guide/";
      retailerSegment = "browse-color-stains/";
    } else {
      retailerSegment = this.getRetailerSegment(color.channelId?.toString());
    }
    const colorFamilySegment = this.getColorFamilySegment(color.colorFamily);
    const colorDetailSegment = this.getColorDetailSegment(color);
    const href =
      colorsListSegment +
      retailerSegment +
      colorFamilySegment +
      colorDetailSegment +
      this.cdpLinkExtension;

    return href.includes("undefined") ? "#" : href.toLowerCase();
  }

  private getColorPath(mdmType: string) {
    let retailerSegment = "color-library/";

    switch (mdmType) {
      case "Paint":
        retailerSegment += "paint/";
        break;

      case "Wood Stain":
        retailerSegment += "wood-stain/";
        break;

      case "Concrete Stain":
        retailerSegment += "concrete-stain/";
        break;
      default:
        retailerSegment = "";
    }

    return retailerSegment;
  }

  private renderAriaLabel(ariaLabel) {
    return ariaLabel ? `aria-label="${ariaLabel}"` : "";
  }

  private renderColorCard(
    color: FavoriteColorItem,
    productName: string
  ): string {
    if (typeof color.colorName === "undefined") return "";

    const isCotySwatch = color.colorNumber === "COTY";
    const cotyStyleBlock = `"background-color:none; background-size:cover; background-repeat:no-repeat; background-image:url(${this.cotyBackground});"`;
    const favStyleBlock = `"--chip-color: ${
      color.hex || color.hexColor || `url(${color.swatchUrl})`
    };"`;

    const transparency = color.transparency === "undefined";
    if (this.currentBrand === "minwax") {
      const htmlFeed = this.renderMinwaxFav(
        color,
        isCotySwatch,
        cotyStyleBlock,
        favStyleBlock,
        transparency,
        productName
      );
      return `${htmlFeed}`;
    }
    return `
        <div class="cbg-cmp-card__swatch-container swatch-container cbg-colorCard favorite-page-swatch">
            <div class="color-anchor">
                <a class="color-detail-link" href="${this.getColorDetailUrl(
                  color
                )}">
                    <div class="cbg-color-swatch"
                         title="${color.colorName}"
                         style=${isCotySwatch ? cotyStyleBlock : favStyleBlock}>
                    </div>
                    <div class="cbg-colorCard__info"
                         data-tags="${color.tags || ""}"
                         title="${color.colorName || color.collectionName}"
                         data-fav-color-id="${color.colorNumber}"
                         data-fav-color-name="${color.colorName}"
                         data-mdmid="${color.mdmColorId}"
                         data-opacity="${
                           transparency
                             ? color.transparency != "undefined"
                             : color.transparency || ""
                         }"
                         >
                        <h4 class="cbg-colorCard__color-name">
                            ${color.colorName}
                        </h4>
                        <h5 class="cbg-colorCard__color-id">
                            ${
                              isCotySwatch
                                ? this.textForCoty
                                : color.colorNumber || color.colorId
                            }
                        </h5>
                        <h5 class="cbg-colorCard__color-transparency">
                            ${
                              transparency
                                ? color.transparency != "undefined"
                                : color.transparency || ""
                            }
                        </h5>
                        ${Favorites.renderTagEmblem(color)}
                    </div>
                </a>
                <button class="cbg-favorite-cta"
                           data-fav-id="${color.id}"
                           title="${color.colorName || color.collectionName}"
                           data-fav-color-id="${color.colorNumber}"
                           data-fav-color-name="${color.colorName}"
                           data-fav-channel-id="${color.channelId}"
                           data-fav-hex="${color.hex || color.hexColor}"
                           data-fav-tags="${color.tags}"
                           data-cbg-cmp="favorite-cta"
                           data-mdmid="${color.mdmColorId}"
                           ${this.renderAriaLabel(
                             this.colorFavCtaAriaLabel
                           )}></button>
            </div>
            ${this.renderCartButton(color)}
            ${this.renderBuySampleButton(color)}
        </div>
    `;
  }

  private async getProductData<T>(url: string | undefined): Promise<T | null> {
    if (!url) return null;
    const response = await fetch(url);
    return (await response.json()) as T;
  }

  private async getFavoritesHTML(favorites: FavoriteColorItem[]) {
    const productsDataLocation: string | undefined =
      document.querySelector<HTMLElement>(".cbg-cmp-favorites__content").dataset
        .productsDataLocation;
    const productData = await this.getProductData<ProductT[]>(
      productsDataLocation
    );

    return favorites
      .map((item: FavoriteColorItem) => {
        let productName = "";
        if (productData && item?.associatedProducts?.[0]?.mdmId !== undefined) {
          productName = productData.find(
            (product) =>
              product.productId === item?.associatedProducts?.[0]?.mdmId
          ).productName;
        }
        if (item.tags) {
          this.setFavoriteTagEmblem(item);
        }

        return this.renderColorCard(item, productName);
      })
      .join("\n");
  }

  private initializeCTAs(identifier: "cart-cta" | "favorite-cta", cta: CTA) {
    this.component
      .querySelectorAll(`[${Selector.component}="${identifier}"]`)
      .forEach((el: HTMLElement) => {
        if (el.dataset.cmpInit !== "true") {
          el.dataset.cmpInit = "true";

          new cta(<HTMLElement>el);
        }
      });
  }

  removeAllChildElements(element: Element): void {
    while (element?.firstChild) {
      element.removeChild(element.firstChild);
    }
  }

  private updateColorContent(favorites: FavoriteColorItem[]) {
    const hasFavesSaved = favorites.length > 0;
    const section = this.colorsSection || this.populatedContentContainer;
    const content = this.colorsContent || this.populatedContentContainer;

    if (hasFavesSaved) {
      this.getFavoritesHTML(favorites).then((favoritesHTML) => {
        content.innerHTML = favoritesHTML;
        this.initializeCTAs("cart-cta", CartCTA);
        this.initializeCTAs("favorite-cta", FavoriteCTA);
        this.updateElementVisibility(section, false);
      });
    } else {
      this.removeAllChildElements(content);
      this.updateElementVisibility(section, !this.editMode);
    }
  }

  private addRemainingColorsContent(
    chipColors: string[],
    chips: NodeListOf<HTMLElement>,
    moreChipCount: Element
  ) {
    const remainingColorsCount = chipColors.length - chips.length;

    if (remainingColorsCount) {
      moreChipCount.textContent = `+${remainingColorsCount} ${this.moreColorsText}`;
    }
  }

  private addColorContent(
    collectionCard: HTMLElement,
    chips: NodeListOf<HTMLElement>,
    chipColors: string[]
  ) {
    for (let i = chips.length - 1; i >= 0; i--) {
      const chipColor = chipColors[i] || `#${i}${i}${i}`;

      if (i === 0) {
        collectionCard.style.setProperty("--border-color", chipColor);
      }

      chips[i].style.setProperty("--chip-color", chipColor);
    }
  }

  private getCollectionDetailSegment(color: FavoriteColorItem): string {
    const collectionName = color.collection || color.colorNumber;

    if (!collectionName) return;

    return collectionName === "COTY"
      ? this.cotyDetailUrlSegment
      : collectionName.replace(/\s/g, "-"); // Replace spaces with hyphens
  }

  private getCollectionDetailUrl(color: FavoriteColorItem): string {
    const collectionsListSegment = "design-help/color-collections/";
    const collectionDetailSegment = this.getCollectionDetailSegment(color);
    const href =
      collectionsListSegment + collectionDetailSegment + this.cdpLinkExtension;

    return href.includes("undefined") ? "#" : href.toLowerCase();
  }

  private addCollectionDetailContent(
    link: HTMLAnchorElement,
    favorite: FavoriteColorItem
  ) {
    link.href = this.getCollectionDetailUrl(favorite);
  }

  private addCollectionTitleContent(
    title: HTMLElement,
    favorite: FavoriteColorItem
  ) {
    title.style.textTransform = "capitalize";
    title.textContent = (
      favorite.collection || favorite.colorName
    ).toLowerCase();
  }

  private addCollectionDescriptionContent(
    favorite: FavoriteColorItem,
    cotyDescription: Element
  ) {
    if (favorite.colorNumber === "COTY" && this.cotyDescriptionText) {
      cotyDescription.textContent = this.cotyDescriptionText;
    } else {
      cotyDescription.classList.add(Classnames.displayNone);
    }
  }

  private addFavoritesCtaContent(
    favoritesCta: HTMLElement,
    favorite: FavoriteColorItem
  ) {
    const collection = favorite.collection || favorite.colorNumber;

    favoritesCta.dataset.collection = collection;
    favoritesCta.dataset.favColorId = collection;
  }

  private addAllChipsButtonContent(
    favorite: FavoriteColorItem,
    addAllChipsButton: HTMLElement
  ) {
    const addChips = addAllChipsButton.querySelector(".add-chips-to-cart-text");
    const removeChips = addAllChipsButton.querySelector(
      ".remove-chips-from-cart-text"
    );

    // Add authored content for Cart Button
    addAllChipsButton.setAttribute("data-cbg-cmp", "cart-cta");
    addAllChipsButton.setAttribute("data-channel-id", favorite.channelId);
    addAllChipsButton.setAttribute("data-collection", favorite.collection);
    addAllChipsButton.setAttribute("data-locale", favorite.localeId);
    addChips.textContent = this.addAllChipsText;
    removeChips.textContent = this.removeAllChipsText;

    // Display Cart Button
    this.updateElementVisibility(addAllChipsButton, false);
  }

  private populateCollectionCard(
    favorite: FavoriteColorItem,
    clonedTemplate: HTMLElement
  ): Node {
    // Get Elements to populate with content
    const ccdpLink = clonedTemplate.querySelector<HTMLAnchorElement>(
      ".collection-detail-link"
    );
    const collectionCard = clonedTemplate.querySelector<HTMLElement>(
      ".collection-card-home"
    );
    const chips = clonedTemplate.querySelectorAll<HTMLElement>(".each-chip");
    const moreChipCount = clonedTemplate.querySelector(".total-chip-count");
    const title = clonedTemplate.querySelector<HTMLElement>(".each-title");
    const cotyDescription = clonedTemplate.querySelector(
      ".collections-description"
    );
    const favoritesCta = clonedTemplate.querySelector<HTMLElement>(
      '[data-cbg-cmp="favorite-cta"]'
    );
    const addAllChipsButton = clonedTemplate.querySelector<HTMLElement>(
      ".collection-cart-button"
    );

    const chipColors = favorite.colors || [];

    // Add Content to Elements
    this.addCollectionDetailContent(ccdpLink, favorite);
    this.addColorContent(collectionCard, chips, chipColors);
    this.addRemainingColorsContent(chipColors, chips, moreChipCount);
    this.addCollectionTitleContent(title, favorite);
    this.addCollectionDescriptionContent(favorite, cotyDescription);
    this.addFavoritesCtaContent(favoritesCta, favorite);
    this.addAllChipsButtonContent(favorite, addAllChipsButton);

    return clonedTemplate;
  }

  private updateCollectionContent(favorites: FavoriteColorItem[]) {
    this.removeAllChildElements(this.collectionsContent);

    const hasFavesSaved = favorites.length > 0;

    if (hasFavesSaved) {
      favorites.forEach((favorite) => {
        const clonedTemplate = this.collectionsTemplate.content.cloneNode(
          true
        ) as HTMLElement;
        const collectionCard = this.populateCollectionCard(
          favorite,
          clonedTemplate
        );

        this.collectionsContent.appendChild(collectionCard);
      });

      this.initializeCTAs("cart-cta", CartCTA);
      this.initializeCTAs("favorite-cta", FavoriteCTA);
      this.updateElementVisibility(this.collectionsSection, false);
    } else {
      this.updateElementVisibility(this.collectionsSection, !this.editMode);
    }
  }

  private updatePageUI(favorites: FavoriteColorItem[]) {
    const hasFavesSaved = favorites.length > 0;
    const hasColorsAndCollections = Boolean(
      this.colorsSection && this.collectionsSection
    );

    // Add the loading class to the content container.
    this.contentContainer?.classList.add(Classnames.loading);

    this.updateElementVisibility(
      this.emptyContentContainer,
      hasFavesSaved && !this.editMode
    );

    if (!hasFavesSaved && !this.editMode) {
      this.favContent.classList.add(Classnames.emptyContainer);
      if (this.visualizerButtonContainer != null) {
        this.visualizerButtonContainer.classList.add("display-none");
      }
    }

    if (hasColorsAndCollections) {
      const colorFavorites = favorites.filter((fav) => Boolean(fav.colorId));
      const collectionFavorites = favorites.filter((fav) => !fav.colorId);

      this.favContent.classList.add(Classnames.visualizeButton);

      this.updateColorContent(colorFavorites);
      this.updateCollectionContent(collectionFavorites);
    } else {
      this.updateColorContent(favorites);
    }

    // Remove the loading class from the content container.
    this.contentContainer?.classList.remove(Classnames.loading);
  }

  /**
   * Registers subscribers for published messages.
   */
  private registerMessageSubscribers(): void {
    // Subscribe to the message when a favorite is added.
    Utils.msg.subscribe(Message.addFavoritesResponse, (response) => {
      this.updateHeaderUI(response.favorites || response || []);
    });

    // Subscribe to the message when a favorite is removed.
    Utils.msg.subscribe(Message.removeFromFavoritesResponse, (response) => {
      this.updateHeaderUI(response.favorites || response || []);

      if (this.context === "page") {
        this.updatePageUI(response.favorites || response || []);
      }
    });

    // Subscribe to the message when the getCart response is received from the AskVal api.
    Utils.msg.subscribe(Message.getCartResponse, (response) => {
      this.updateHeaderUI(response.favorites || response || []);

      if (this.context === "page") {
        this.updatePageUI(response.favorites || response || []);
      }
    });
  }
}

export { Favorites };
