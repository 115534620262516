enum Message {
  filterChanged = "filter-option::option-changed",
  filterUnchecked = "filter-option::option-unchecked",
  filterClearAll = "filter-option::clear-all",

  formOptionChange = "form-option::option-changed",

  searchQuery = "search::query",
  wallSearchQuery = "search::wallQuery",
  searchClearInput = "search::clearInput",
  searchSyncValues = "search::syncValues",
  algoliaRefineResults = "algolia::refineResults",

  addToCart = "cart::addItem::request",
  addToCartResponse = "cart::addItem::response",

  removeFromCart = "cart::removeItem",
  removeFromCartResponse = "cart::removeItem::response",

  getCart = "cart::getCart::request",
  getCartResponse = "cart::getCart::response",

  updateColorChipState = "colorChip::update",

  cookieUpdated = "cookie::updated",

  addToFavorites = "favorites::addToFavorites",
  addFavoritesResponse = "favorites::addItem::response",
  addFavoritesErrorResponse = "favorites::addItemError::response",
  removeFromFavorites = "favorites::removeFromFavorites",
  removeFromFavoritesResponse = "favorites::removeItem::response",
  notificationsBannerState = "notificationsBanner::notificationsBannerState",
  tooManyCollectionsMsgId = "500",
}

export { Message };
