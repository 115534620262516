// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination } from "swiper";

// configure Swiper to use modules
Swiper.use([Navigation, Pagination]);

class InspirationCarousel {
  container: HTMLElement;
  howManySlides: number;
  howManySlidesMobile: number;
  slidesPerTransition: number;
  spaceBetween: number;
  spaceBetweenMobile: number;
  carouselId: string;
  navigationStyle: string;

  constructor(component: HTMLElement) {
    // Elements
    this.container = component;
    console.log("swiping...");
    this.howManySlides = parseInt(this.container.dataset.howManySlides);
    this.howManySlidesMobile = parseInt(
      this.container.dataset.howManySlidesMobile
    );
    this.spaceBetween = parseInt(this.container.dataset.spaceBetween);
    this.spaceBetweenMobile = parseInt(
      this.container.dataset.spaceBetweenMobile
    );
    this.slidesPerTransition = parseInt(
      this.container.dataset.slidesPerTransition
    );

    if (!this.slidesPerTransition) {
      this.slidesPerTransition = 1;
    }

    if (!this.container) {
      return null;
    }

    this.carouselId = this.container.id;
    this.navigationStyle = this.container.dataset.navigationStyle;

    const swiper = new Swiper(`.${this.carouselId}`, {
      spaceBetween: this.spaceBetween,
      slidesPerView: this.howManySlides,
      slidesPerGroup: this.slidesPerTransition,
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: this.howManySlidesMobile,
          spaceBetween: this.spaceBetweenMobile,
        },
        // when window width is >= 480px
        780: {
          slidesPerView: this.howManySlidesMobile,
          spaceBetween: this.spaceBetweenMobile,
        },
        // when window width is >= 640px
        1200: {
          slidesPerView: this.howManySlides,
          spaceBetween: this.spaceBetween,
        },
      },
      pagination: {
        el: ".swiper-pagination",
        type:
          this.navigationStyle == "fractions" ||
          this.navigationStyle == "arrows_fractions"
            ? "fraction"
            : "bullets",
        clickable: true,
      },
      navigation: {
        nextEl: `#${this.carouselId} .swiper-button-next`,
        prevEl: `#${this.carouselId} .swiper-button-prev`,
      },
    });
  }
}

export { InspirationCarousel };
