import { Cookie, Utils } from "../../site/scripts/utils";

enum Classnames {
  modalActive = "modal__active",
  modalFullScreen = "modal__fullscreen",
}

enum Selector {
  hook = "data-cmp-hook-modal",
  element = "data-component-id",
}

class Modal {
  // Elements
  container: HTMLElement;
  backButton: HTMLElement;
  closeButton: HTMLElement;
  overlay: HTMLElement;
  videoPlayer: HTMLElement;
  combinedInput: HTMLElement;

  // Options
  showOnLoad: boolean;
  loadDelay: number;
  isSignUp: boolean;
  isPrismSearch: boolean;

  // Sign Up Cookie
  signUpCookie: void;
  signUpCookieValue: string;
  showSignUp: string;
  showOnCookieValue: string;
  // Edit Mode Cookie
  editMode: boolean;

  constructor(component: HTMLElement) {
    this.container = component;
    const dataSet = this.container.dataset;

    if (!this.container) {
      return;
    }

    this.backButton =
      this.container.querySelector(`[${Selector.hook}="back-button"]`) || null;
    this.closeButton =
      this.container.querySelector(`[${Selector.hook}="close-button"]`) || null;
    this.overlay =
      this.container.querySelector(`[${Selector.hook}="overlay"]`) || null;
    this.videoPlayer = this.container.querySelector("iframe") || null;

    this.combinedInput = this.container.querySelector(".combinedinput-home");

    // Set options
    const data = this.container.dataset;
    this.editMode = data && data.editMode && data.editMode === "edit";
    this.showOnLoad =
      data && data.cmpShowModalOnLoad && data.cmpShowModalOnLoad === "true";

    this.isSignUp = data.cmpSignUpModal === "true";

    if (this.combinedInput) {
      const dataSet = this.combinedInput.dataset;
      for (const key in dataSet) {
        if (key === "fieldNoEmailCookie" && dataSet[key] !== "") {
          this.signUpCookieValue = Cookie.get(`${dataSet[key]}`);
        }
        if (key === "fieldCookieId") {
          this.showSignUp = Cookie.get(`${dataSet[key]}`);
        }
      }
    } else {
      this.signUpCookieValue = Cookie.get("emailSignupDoNotShow");
    }

    this.loadDelay =
      data && !isNaN(parseFloat(data.cmpModalDelay))
        ? parseFloat(data.cmpModalDelay)
        : 0;

    this.registerEventHandlers();

    if (
      this.showOnLoad &&
      ((this.isSignUp &&
        this.showSignUp == undefined &&
        !this.signUpCookieValue) ||
        !this.isSignUp)
    ) {
      this.registerOnLoadHandler();
    }

    if (dataSet.cmpShowOnCookiePresence === "true") {
      if (Cookie.get(dataSet.cmpCookieLabel) === dataSet.cmpCookieValue) {
        this.registerOnLoadHandler();
      }
    }
  }

  private isFullScreen() {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    const modalHeight = this.container.clientHeight;
    const modalWidth = this.container.clientWidth;

    return (
      modalHeight >= viewportHeight * 0.8 && modalWidth >= viewportWidth * 0.8
    );
  }

  public openModal(delay = 0) {
    try {
      if (!this.editMode) {
        this.container?.classList?.add(Classnames.modalActive);
        document.querySelector("body").classList.add(Classnames.modalActive);
        //Removing to check fullscreen issue
        if (this.isFullScreen()) {
          document
            .querySelector("body")
            .classList.add(Classnames.modalFullScreen);
        }
      }
    } catch (e) {
      console.error("Modal.openModal error", e);
    }
  }

  private closeModal() {
    try {
      this.container.classList.remove(Classnames.modalActive);
      document
        .querySelector("body")
        .classList.remove(Classnames.modalActive, Classnames.modalFullScreen);

      // stop playing video in modal
      if (this.videoPlayer) {
        const tempSrc = this.videoPlayer.getAttribute("src");
        this.videoPlayer.setAttribute("src", "");
        this.videoPlayer.setAttribute("src", tempSrc);
      }

      Utils.restoreNormalScroll();
    } catch (e) {
      console.error("Modal.closeModal error", e);
    }
  }

  private registerEventHandlers() {
    if (this.isSignUp) {
      if (this.closeButton) {
        this.closeButton.addEventListener("click", () => {
          try {
            // Set Cookie
            if (this.combinedInput) {
              const dataSet = this.combinedInput.dataset;
              for (const key in dataSet) {
                if (key === "fieldNoEmailCookie" && dataSet[key] !== "") {
                  this.signUpCookie = Cookie.set(`${dataSet[key]}`, "30", 30);
                }
              }
            } else {
              this.signUpCookie = Cookie.set("emailSignupDoNotShow", "30", 30);
            }
            this.container.classList.remove(Classnames.modalActive);
            document
              .querySelector("body")
              .classList.remove(Classnames.modalActive);

            // stop playing video in modal
            if (this.videoPlayer) {
              const tempSrc = this.videoPlayer.getAttribute("src");
              this.videoPlayer.setAttribute("src", "");
              this.videoPlayer.setAttribute("src", tempSrc);
            }
          } catch (e) {
            console.error("Modal.closeModal error", e);
          }
        });
      }
    } else {
      if (this.backButton) {
        this.backButton.addEventListener(
          "click",
          (() => {
            this.closeModal();
          }).bind(this)
        );
      }

      if (this.closeButton) {
        this.closeButton.addEventListener(
          "click",
          (() => {
            this.closeModal();
          }).bind(this)
        );
      }

      if (this.overlay) {
        this.overlay.addEventListener(
          "click",
          (() => {
            this.closeModal();
          }).bind(this)
        );
      }

      if (this.overlay) {
        window.addEventListener("prismSearchClose", () => {
          this.closeModal();
        });
      }
    }
  }

  private registerOnLoadHandler() {
    Utils.ready(
      function () {
        const delay = this.loadDelay * 1000;

        if (delay) {
          setTimeout(
            function () {
              this.openModal(true);
            }.bind(this),
            delay
          );
          return;
        }
        this.openModal(false);
      }.bind(this)
    );
  }
}

export { Modal };
