import { Favorites } from "./Favorites";
import { FavoriteCTA } from "./FavoriteCTA";
import { FavoriteColorItem, FavoritesAPI } from "./FavoritesController";

document
  .querySelectorAll('[data-cbg-cmp="favorites"]')
  .forEach((element: HTMLElement) => {
    // Check for COTY Collection class to determine which component to instantiate
    return element.classList.contains("cbg-coty-collection-favorite")
      ? new FavoriteCTA(element)
      : new Favorites(element);
  });

document
  .querySelectorAll('[data-cbg-cmp="favorite-cta"]')
  .forEach((element: HTMLElement) => {
    if (element.dataset.cmpInit !== "true") {
      element.dataset.cmpInit = "true";

      new FavoriteCTA(element);
    }
  });

// Listen to global click events to establish FavoritesCTA instances when applicable.
window.addEventListener("click", FavoriteCTA.handleGlobalClickEvent);

window.addEventListener(
  "ADD_FAVORITE_REACT",
  (event: CustomEvent<{ color: FavoriteColorItem }>) => {
    console.log(event, FavoritesAPI);
    FavoritesAPI.add(event.detail.color);
  }
);

window.addEventListener(
  "REMOVE_FAVORITE_REACT",
  (event: CustomEvent<{ colorId?: string }>) => {
    console.log(event, FavoritesAPI);
    FavoritesAPI.remove(event.detail.colorId);
  }
);
