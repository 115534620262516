class ScrollTo {
  container: HTMLElement;

  constructor(component: HTMLElement) {
    this.container = component;
    if (!this.container) {
      return null;
    }
    this.init();
  }

  /**
   * Initialize the component
   */
  private init(): void {
    this.buttonScrollTo = this.container.querySelector(".scroll-to-button");
    this.buttonScrollTo.onclick = this.clickHandler;
    this.autoDetectScrollBehavior();
  }

  /**
   * Handle button click
   */
  private clickHandler = (): void => {
    this.scrollToTop();
    const focusableElement: HTMLElement = this.getFirstFocusableElement();
    this.setFocus(focusableElement, true);
    return;
  };

  /**
   * Return the first element in the document that can receive focus
   */
  private getFirstFocusableElement = (): HTMLElement => {
    const focusableElements: NodeListOf<HTMLElement> =
      document.querySelectorAll(
        'button, a:not([href="#mainContent"]), input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
    return focusableElements[0];
  };

  /**
   * Focus the specified element, preventing scroll if required
   */
  private setFocus = (element: HTMLElement, preventScroll = false): void => {
    element.focus({ preventScroll: preventScroll });
  };

  /**
   * Set smooth vs. instant scroll based on OS accessibility settings
   */
  private autoDetectScrollBehavior = (): void => {
    this.scrollBehavior = window.matchMedia("(prefers-reduced-motion: reduce)")
      .matches
      ? ("auto" as ScrollBehavior)
      : ("smooth" as ScrollBehavior);
  };

  /**
   * Scroll to the top of the window
   */
  private scrollToTop = (): void => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: this.scrollBehavior,
    });
  };

  private buttonScrollTo;
  private scrollBehavior: ScrollBehavior = "smooth";
}

export { ScrollTo };
