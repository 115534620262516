import { Cookie } from "../../site/scripts/utils";

class Form {
  // Elements
  private readonly formElement: HTMLFormElement;
  private readonly isSignUp: boolean;

  // Sign Up Cookie
  private signUpFormCookie: void;
  private signUpFormCookieValue: string;
  private readonly isStandardFormSubmit: boolean;

  constructor(component: HTMLFormElement) {
    this.formElement = component;

    if (!this.formElement) {
      return null;
    }

    const data = this.formElement.dataset;

    this.isStandardFormSubmit =
      data.formType === "formSubmit" || data.formType === undefined;
    this.isSignUp = data.cmpSignUpForm === "true";
    this.signUpFormCookieValue = Cookie.get("showSignUpModal");

    this.registerEventHandlers();
  }

  private registerEventHandlers(): void {
    console.debug("register form handlers");

    if (this.isStandardFormSubmit) {
      console.debug("handlers added for standard form submit");
      this.formElement.addEventListener(
        "submit",
        this.submitHandler.bind(this)
      );
    }

    if (this.isSignUp) {
      this.formElement.addEventListener(
        "submit",
        this.signUpRequired.bind(this)
      );
      return;
    }
  }

  private signUpRequired(): void {
    this.signUpFormCookie = Cookie.set("showSignUpModal", "never", 10000);
  }

  // Prevents default form submit event in order to validate input fields first
  private submitHandler(event: Event) {
    event.preventDefault();
    if (!this.formElement.checkValidity()) {
      return false;
    }

    console.debug("Submitting using default submit handler");
    this.formElement.submit();
  }
}

export { Form };
