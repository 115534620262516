import { ResultsContainer, AlgoliaSearchResult } from "./ResultsContainer";

class ContentResultsContainer extends ResultsContainer {
  resultsIndexKey = "wp_post_index";
  type = "content";

  constructor() {
    super();
    this.setContainerFromType();
  }

  updateWithResults(results: AlgoliaSearchResult): void {
    results?.hits?.forEach((hit) => {
      const title = hit.post_title;
      const desc = hit.prod_description;
      const slug = hit.slug;

      const html = this.getTemplateFromType();
      const titleEl = html.querySelector(
        `[${this.resultsHook}="contenttitle"]`
      );
      const descriptionEl = html.querySelector(
        `[${this.resultsHook}="contentdesc"]`
      );

      if (title && titleEl) {
        titleEl.innerHTML = title;
        titleEl.setAttribute("href", slug);
      }

      if (desc && descriptionEl) {
        descriptionEl.innerHTML = desc;
        descriptionEl.setAttribute("href", slug);
      }

      try {
        this.container.append(html);
      } catch (error) {
        console.warn("Issue adding ContentResults", error);
      }
    });
  }
}

export { ContentResultsContainer };
