class DynamicFlex {
  viewMoreToggle: string;
  showFragmentContainer: boolean;
  viewMoreButtonColors: HTMLElement;
  viewMoreButtonProducts: HTMLElement;
  viewMoreSectionColors: HTMLElement;
  viewMoreSectionProducts: HTMLElement;
  container: HTMLElement;

  constructor(component: HTMLElement) {
    // Elements
    this.container = component;
    console.log("flexing...");
    this.showFragmentContainer = false;
    this.viewMoreToggle = this.container.dataset.viewMoreToggle;

    this.viewMoreButtonColors = this.container.querySelector(
      ".view-more-button-colors"
    );
    this.viewMoreSectionColors = this.container.querySelector(
      ".view-more-section-colors"
    );
    this.viewMoreButtonProducts = this.container.querySelector(
      ".view-more-button-products"
    );
    this.viewMoreSectionProducts = this.container.querySelector(
      ".view-more-section-products"
    );

    if (!this.container) {
      return null;
    }
    let colorCount = 0;
    this.container
      .querySelectorAll(".cmp-dynamicFlexContainer__card-item.color-card")
      .forEach((el) => {
        if (this.viewMoreToggle) {
          if (colorCount < Number(this.viewMoreToggle)) {
            el.classList.remove("hide-card");
          } else {
            el.classList.add("hide-card");
          }
          colorCount++;
        }
      });

    let productCount = 0;
    this.container
      .querySelectorAll(".cmp-dynamicFlexContainer__card-item.product-card")
      .forEach((el) => {
        if (this.viewMoreToggle) {
          if (productCount < Number(this.viewMoreToggle)) {
            el.classList.remove("hide-card");
          } else {
            el.classList.add("hide-card");
          }
          productCount++;
        }
      });

    this.registerEventHandlers();
  }

  private registerEventHandlers() {
    if (this.viewMoreSectionProducts) {
      this.viewMoreSectionProducts.addEventListener(
        "click",
        ((e) => {
          e.stopPropagation();
          //hide the View More section
          if (this.viewMoreSectionProducts.classList) {
            this.viewMoreSectionColors.classList.add("hide-card");
          }
          //show the other cards
          this.container
            .querySelectorAll(".card-item.product-card")
            .forEach((el) => {
              el.classList.remove("hide-card");
            });
        }).bind(this)
      );
    }

    if (this.viewMoreSectionColors) {
      this.viewMoreSectionColors.addEventListener(
        "click",
        ((e) => {
          e.stopPropagation();
          //hide the View More section
          if (this.viewMoreSectionColors.classList) {
            this.viewMoreSectionColors.classList.add("hide-card");
          }
          //show the other cards
          this.container
            .querySelectorAll(".card-item.color-card")
            .forEach((el) => {
              el.classList.remove("hide-card");
            });
        }).bind(this)
      );
    }
  }
}

export { DynamicFlex };
