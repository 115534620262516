interface AlgoliaSearchResult {
  hits: any[];
  nbHits: number;
  page: number;
  nbPage: number;
  hitsPerPage: number;
  exhaustiveNbHits: boolean;
  query: string;
  params: string;
  index: string;
  processingTimeMS: number;
}

interface ResultsContainerInterface {
  container: HTMLElement;
  type: string;
  resultsIndexKey: string;
}

/**
 * The blueprint for displaying search Algolia results to the page.
 */
class ResultsContainer implements ResultsContainerInterface {
  container: HTMLElement;
  type: string;
  resultsHook = "data-searchresult-hook";
  resultsIndexKey: string;

  /**
   * Updates the template markup with content from the provided results
   *  and writes the update markup into the container.
   *
   *  @param results: AlgoliaSearchResult
   *  @returns void
   */
  updateWithResults(results: AlgoliaSearchResult): void {
    console.error(
      "updateWithResults :: Not implemented for base class",
      this.type,
      results
    );
  }

  /**
   * Returns the search results template html that is used for results
   *  of the corresponding type.
   *  See src/main/content/jcr_root/apps/cbg-platform/components/platform/searchresults/searchresults.html.
   *
   *  @returns HTMLElement
   */
  getTemplateFromType(): HTMLElement {
    const hook = "data-cmp-searchresults-template";
    const template = <HTMLTemplateElement>(
      document.querySelector(`[${hook}="${this.type}"]`)
    );
    return <HTMLElement>template?.content?.firstElementChild?.cloneNode(true);
  }

  /**
   * Sets the search results container into a variable using a
   *  predefined data attribute.
   *  See src/main/content/jcr_root/apps/cbg-platform/components/platform/searchresults/searchresults.html.
   *
   *  @returns void
   */
  setContainerFromType(): void {
    const hook = "data-results-container-type";
    this.container = document.querySelector(`[${hook}="${this.type}"]`);
  }
}

export { ResultsContainer, AlgoliaSearchResult };
